import React from 'react';
import styled from 'styled-components';
import {FaTimes, FaStarOfLife} from 'react-icons/fa';
import APICall from '../../Common/APICall';
import Store from '../../Common/Store.js';
import Dropzone from '../Common/Dropzone';
import CSLLoader from '../Common/CSLLoader';
import DatePicker from 'react-date-picker';
import {FaCalendarAlt} from 'react-icons/fa';
import CSLTextInput from './../Common/CSLTextInput';
import moment from 'moment';
import AlertBox from '../Common/AlertBox'
import currency_codes from './../Moduleconfig/currency_codes.js'
import { blockInvalidChar } from "../Common/blockInvalidChar";
import Utils from './../../Common/Utils.js';
const MRModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 80%;
    position: absolute;    
    z-index: 1001;
    background-color: rgb(243, 247, 251);
    box-shadow: rgb(167, 166, 166) 0px 0px 5px;
    margin-bottom: 10px;
    border-radius: 5px;
    margin-left: 100px;
`;
const MRModalHeader = styled.div`
    background-color: white;
    display: block;
    padding-top: 5px;
    font-size: 14px;
    font-weight: 700;
    height: 100px;    
    width: calc(100% - 10px);
    border-width: 1px 1px 1px 10px;    
    border-left: 9px solid #04ADA8; 
    border-bottom: 1px solid #DFDFDF;
`;
const MRModalHeaderText = styled.div`
    font-weight: 700;
    font-size: 22px;
    float: left;
    margin-top: 24px;
    margin-left: 35px;  
    color: #143151; 
`;
const MRModalHeaderCloseBtn = styled.div`
    position: absolute;
    top: 8px;
    right: 10px;
    box-sizing: border-box;
    margin-top: 24px;
    margin-right: 20px;
    font-size: 25px;
    cursor: pointer;
    font-weight: 200;
`;
const MRModalBody = styled.div`
    display: block;
    box-sizing: border-box;
    padding: 10px 20px 10px 20px;
`;
const MRModalLabel = styled.div`
    color: #030303;
    font-weight: 600;
    margin-top: 15px;
    font-size: 15px;
    margin-bottom: 15px;
`;
const MRModalFooter = styled.div`
    position: relative;
    background-color: white;
    height: 80px;
    border-style: solid;
    border-width: 1px;
    border-color: #f3f7fb;
    width: 100%;
    border-top: 1px solid #DFDFDF;
`;
const MRModalNextBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #11ABA6;
    color: #ffffff;
    width: 145px;
    height: 45px;
    border-radius: 4px;
    border: 1px solid #11ABA6;
    margin-left: 5px;
    font-size: 15px;
    margin-left: 10px;
    float: right;
    cursor: pointer;
`;
const MRModalSaveCloseBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #143151;
    color: #ffffff;
    width: 145px;
    height: 45px;
    border-radius: 4px;
    border: 1px solid #143151;
    margin-left: 5px;
    font-size: 15px;
    margin-left: 10px;
    float: right;
    cursor: pointer;
`;
const MRModalTextarea = styled.textarea`
    width: 99%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #000000;
    font-family: 'Montserrat', sans-serif;
`;
const MRModalCancelBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #545454;
    border: 2px solid #dadada;
    width: 145px;
    height: 45px;
    border-radius: 4px;
    font-size: 15px;
    float: right;
    cursor: pointer;
`;
const ModalNameLabelDivReference = styled.div`
    height: auto;
    width: 100%;    
    font-weight: 600;
    color: #282828;    
    display:inline;
`;
const MRModalSelect = styled.select`
    box-sizing: border-box;
    height: 40px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: #444444;    
    padding: 10px;
    font-weight: 600;
`;
const MRModalInput = styled.input`
    height: 25px;
    width: 99%;
    box-shadow: rgb(196, 196, 196) 0px 0px 4px;
    color: #545454;
    font-family: montserrat;
    font-size: 14px;
    font-weight: 600;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(255, 255, 255);
    border-image: initial;
    border-radius: 3px;
    padding: 6px;
`;
const ModalNameInputDiv = styled.div`
    height: auto;
    width: 100%;    
    color: #282828;
    font-family: 'Montserrat', sans-serif;
`;
const MRModalSubHeaderText = styled.div`
    font-weight: 700;
    font-size: 13px;
    float: left;
    margin-left: 35px;
    margin-bottom: 20px;
    margin-top: 10px;
    color: #636363;
`;
const UserHr = styled.div`
border-top: 2px solid #E9EBED;
`;
const SpacerXL = styled.div`
    height: 20px;
`;
const SpacerL = styled.div`
    height: 10px;
`;
const SpacerS = styled.div`
    height: 5px;
`;
const CSLDateCover = styled.div`   
    margin-top: 8px;
    width: 40%;
    border-radius: 5px;
    border-style: solid;
    border-width: 1px;
    border-color: #282828;
    background-color: #ffffff;
    height: 25px;
    padding-top: 2px;
    padding-left: 2px;
    // float: left;
    position: relative;
`;
const ScoreBodyContainer = styled.div`
  padding: 10px;
  text-align: left;
  overflow-y: scroll;
  height: 555px;
`;

class GMAskGiftModal extends React.Component
{
    state = {
        giftStatus: "received",
        recipientlist: [],
        recipientName: "",
        recipientNameAll: "",
        isOtherRecipientName: false,
        donorName: "",
        giftTypeid: 0,
        giftDescription: "",
        giftTypeList: [],
        currency: "",
        minvalue: 0,
        fixedMinvalue: 0,
        finalMinValueCheck: 0,

        Ref_ID: null,
        submit_form: null,
        curlane: null,
        taskid: null,
        gc_companies: null,
        gc_company: null,
        is_gc: false,
        cur_gc_id:null,
        ready: false,
        companyID:0,
        due_date: '1970-01-01',
        isdesable: false,
        binFiles: [],
        cur_files_json: null,
        is_disabled: false,
        filterAlldatacheck: {},
        currentcompanyName: "",
        giftedDate: new Date(),
        assign_data: null,
        alert_param: null,
        loading: false,
        isShowSpecificUsers: false,
        specificUserID: 0,
        specificUsersList: [],
        giftGivenTo: '',
        app_currency: "",
        app_minvalue: 0,
        isShowAppValue: false
    };

    componentDidMount()
	{
        let index = Store.getStoreData('q_index') === null ? 0 : Store.getStoreData('q_index');
        console.log('index in componentDidMount', index);
        //console.log("contact_id==>",Store.getStoreData('contacts'))
        let contracts = Store.getStoreData('contacts');
        let contactsArray = [];
        let role = Store.getStoreData('role');
        if(role === "admin_manager" || role === "manager" || role === "gm"){
            let otherObject = {id: "Other", name: "Other"}
            contactsArray.push(otherObject);
        }
        let recipientlist = contactsArray.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);} );

        console.log("contactsArray==>",recipientlist);
        Store.updateStore('recipientlistUpdate', recipientlist);
        let giftTypeList = [];
        if('gift_types' in Store.getStoreData('module_config')){
            let giftTypeListValue = Store.getStoreData('module_config').gift_types;
            for(let d of giftTypeListValue){
                if(d.isActive !== 0){
                    giftTypeList.push(d);
                }
            }
        }
        let minimumValue = 0;
        if('approval_level' in Store.getStoreData('module_config') && Store.getStoreData('module_config').approval_level.enable_enhanced_approval === false){
            if('general' in Store.getStoreData('module_config') && 'companyCurrencyDetails' in Store.getStoreData('module_config').general){
                minimumValue = Store.getStoreData('module_config').general.companyCurrencyDetails.minvalue;
            }
        }else if('approval_level' in Store.getStoreData('module_config') && Store.getStoreData('module_config').approval_level.enable_enhanced_approval){
            if(!Store.getStoreData('module_config').approval_level.enable_approval_levels){
                minimumValue = Store.getStoreData('module_config').approval_level.min_val_gift_types;
            }else{
                if(Store.getStoreData('module_config').approval_level.applies_to_all_gift_types){
                    if(Store.getStoreData('module_config').approval_level.approval[0].elem.length === 0){
                        minimumValue = Store.getStoreData('module_config').approval_level.min_val_approval
                    }else{
                        minimumValue = Store.getStoreData('module_config').approval_level.approval[0].elem[0].received.value
                    }
                }else{
                    minimumValue = Store.getStoreData('module_config').approval_level.min_val_approval_giftTypes;
                }
            }
        }

        let currencyValue = "";
        if('general' in Store.getStoreData('module_config') && 'companyCurrencyDetails' in Store.getStoreData('module_config').general){
            currencyValue = Store.getStoreData('module_config').general.companyCurrencyDetails.currency;
        }

        //custom field start
        let module_config = Store.getStoreData('module_config');
        let additional_fields = [];
        if(Object.keys(module_config).length > 0) {
            if (module_config.custom_fields.additional_fields.length !== 0 && module_config.custom_fields.enabled) {
                let arrayAdditionalField = Object.values(module_config.custom_fields.additional_fields);
                arrayAdditionalField.forEach((item) => {
                    if(item.isActive === 1){
                        let copy_item = JSON.parse(JSON.stringify(item));
                        copy_item.value = item.type === "Date" ? '' : "";
                        //copy_item.value = item.type === "Dropdown" ? item.dropdown_options[0] : copy_item.value;
                        copy_item.value = item.type === "Dropdown" ? "" : copy_item.value;
                        additional_fields.push(copy_item);
                    }
                })
            }
        }

        console.log("additional_fields===>", additional_fields)
        //custom field end


        let gc_company = Store.getStoreData('gc_company');
        let gc_companies = Store.getStoreData('gc_companies');
        // console.log("company",gc_company)
        let cur_gc_id = 0;
        let is_gc = false;
        let form_data = {};
        let assign_data = {}
        let gcs = [];
        for(let g in gc_company){
            if(gc_company[g].is_active === 1){
                gcs.push(gc_company[g])
            }
        }

        if(index >= 0) {
            form_data = Store.getStoreData('cur_q_modal_data');
            console.log('form_data', form_data);
            let task_json = JSON.parse(form_data.task_json);
            //let curgc_id = task_json.task_data.group_company_id;
            let curgc_id = form_data.company_id
            let sampleNumber = curgc_id;
            let lastDigitcurgc_id = sampleNumber % 100000;
            console.log("lastDigitcurgc_id===>",lastDigitcurgc_id)
            let gc_company = Store.getStoreData('gc_company');
            let is_gc = false;
            let companyUsers = {};
            let currentcompanyName = "";
            for(let gc of gc_company) {
                if(parseInt(gc.id) === parseInt(lastDigitcurgc_id)){
                    is_gc = gc.is_gc;
                    console.log("gc.id", gc.id);
                    currentcompanyName = gc.company_name
                    companyUsers = gc.users;
                }
            }
            //new
            let contactsArray = [];
            let spUserArray = [];
            if(Object.keys(companyUsers).length !== 0){
                Object.keys(companyUsers).map((d, index) => {
                    if(d in contracts){
                        let contactsObject = {}
                        // contactsObject.id = contracts[d];
                        contactsObject.id = d;
                        contactsObject.name = contracts[d];
                        contactsArray.push(contactsObject);
                        let spUserObject = {}
                        spUserObject.id = d;
                        spUserObject.name = contracts[d];
                        spUserArray.push(spUserObject);
                    }
                })
            }
            let recipientlistold = Store.getStoreData('recipientlistUpdate');
            for(let i=0;i<contactsArray.length;i++){
                recipientlistold.push(contactsArray[i])
            }
            let specificUsersList = [];
            for(let i=0;i<spUserArray.length;i++){
                specificUsersList.push(spUserArray[i])
            }
            //console.log("contactsArray==>==>", recipientlistold)
            let recipientlistnew = recipientlistold.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);} );
            let specificUsersListnew = specificUsersList.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);} );
            //new


            let cur_gc_id = parseInt(lastDigitcurgc_id)
            let companyID = is_gc ? parseInt(gc_company[0].id) * 100000 + cur_gc_id : cur_gc_id;

            if (task_json.object_data.bin_files.length !== 0) {
                task_json.object_data.bin_files.forEach((item) => {
                    item.uid = "uid" in item ? item.uid : this.genKey(10);
                })
            }
            let due_date = new Date(form_data.due_date);

            let isOtherRecipientName = false;
            if(task_json.object_data.headerinfo.check_recipient_value === 'Other'){
                isOtherRecipientName = true;
            }

            let giftedDate = 'giftedDate' in task_json.object_data.headerinfo ? new Date(task_json.object_data.headerinfo.giftedDate) : new Date();

            assign_data.additional_fields = "additional_fields" in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.additional_fields : form_data.cur_lane === 'COMP_GIFTS_TEAMSAVE' ? additional_fields : [];

            let specificUserID = 'specificUserID' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.specificUserID : 0
            let giftTypes = module_config.gift_types;
            let isShowSpecificUsers = false
            if('enable_enhanced_approval' in module_config.approval_level && module_config.approval_level.enable_enhanced_approval && module_config.approval_level.enable_approval_levels === false){
                for(let d of giftTypes){
                    if(d.isActive === 1 && parseInt(d.giftTypeID) === parseInt(task_json.object_data.headerinfo.gift_typeid) && 'approver_level' in d && d.approver_level === 'su'){
                        isShowSpecificUsers = true
                    }
                }
            }

            let minvalue = minimumValue;
            if('approval_level' in module_config && module_config.approval_level.enable_enhanced_approval && module_config.approval_level.enable_approval_levels && module_config.approval_level.applies_to_all_gift_types === false){
                for(let d of module_config.approval_level.approval_giftTypes){
                    if(parseInt(d.giftTypeID) === parseInt(task_json.object_data.headerinfo.gift_typeid)){
                        minvalue = task_json.object_data.headerinfo.gift_status === 'given' ? d.elem[0].given.value : d.elem[0].received.value
                    }
                }
                minimumValue = minvalue
            }

            let contracts_upd = Store.getStoreData('contacts');
            let namecon = {};
            for(let k in contracts_upd){
                namecon[contracts_upd[k]] = k
            }

            let check_recipient_value = task_json.object_data.headerinfo.check_recipient_value;
            if('check_recipient_id' in task_json.object_data.headerinfo && check_recipient_value !== 'Other'){
                check_recipient_value = task_json.object_data.headerinfo.check_recipient_id;
            }else if(check_recipient_value !== 'Other'){
                check_recipient_value = namecon[check_recipient_value]
            }

            let filterAlldatacheck = {};
            filterAlldatacheck.cur_gc_id = lastDigitcurgc_id;
            filterAlldatacheck.recipientName = check_recipient_value;
            filterAlldatacheck.donorName = task_json.object_data.headerinfo.donor_name;
            filterAlldatacheck.giftTypeid = task_json.object_data.headerinfo.gift_typeid;
            filterAlldatacheck.giftDescription = task_json.object_data.headerinfo.gift_description;

            this.setState({ Ref_ID: task_json.object_data.ref_id,
                recipientlist: recipientlistnew,
                giftTypeList: giftTypeList,
                currency: task_json.object_data.headerinfo.currency !== "" ? task_json.object_data.headerinfo.currency : Store.getStoreData('currencyDetails').currency,
                giftStatus: task_json.object_data.headerinfo.gift_status,
                recipientName: check_recipient_value,
                recipientNameAll: task_json.object_data.headerinfo.recipient_name,
                isOtherRecipientName: isOtherRecipientName,
                donorName: task_json.object_data.headerinfo.donor_name,
                giftTypeid: task_json.object_data.headerinfo.gift_typeid,
                minvalue: task_json.object_data.headerinfo.minvalue,
                giftDescription: task_json.object_data.headerinfo.gift_description,
                curlane: form_data.cur_lane,
                taskid: form_data.id, gc_companies: gc_companies, gc_company: gcs, ready: true,
                cur_gc_id: lastDigitcurgc_id, is_gc: is_gc,
                companyID: companyID, due_date: due_date,cur_files_json: task_json.object_data.bin_files, filterAlldatacheck: filterAlldatacheck, currentcompanyName: currentcompanyName,
                giftedDate,
                assign_data: assign_data,
                specificUsersList: specificUsersListnew,
                isShowSpecificUsers,
                specificUserID,
                fixedMinvalue: minimumValue,
                finalMinValueCheck: minimumValue,
                giftGivenTo: 'giftGivenTo' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.giftGivenTo : '',
                app_currency: 'app_currency' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.app_currency : Store.getStoreData('currencyDetails').currency,
                app_minvalue: 'app_minvalue' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.app_minvalue : 0,
                isShowAppValue : 'isShowAppValue' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.isShowAppValue : false
            });
        }else{
            // if(gc_companies.length === 0){
            //     cur_gc_id = parseInt(Store.getStoreData('curCompanyID'));
            // }
            //console.log("Sourav=====>",cur_gc_id);
            let due_date = new Date();

            let gc_company = gcs;
            let userrole = Store.getStoreData('role');
            let update_gc_company = [];
            if(Store.getStoreData('has_gc') && userrole === 'gm' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_gifts' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_gifts === false){
                for(let gc of gc_company) {
                    if(parseInt(gc.id) !== parseInt(Store.getStoreData('curCompanyID'))){
                        update_gc_company.push(gc)
                    }
                }
            }else{
                update_gc_company = gc_company;
            }
            update_gc_company = Utils.sortCompanyNameInAlphabeticalOrder(update_gc_company)            
            let companyUsers = {};
            if(!Store.getStoreData('has_gc')){
                companyUsers = update_gc_company[0].users;
                cur_gc_id = update_gc_company[0].id;
            }
            let contactsArray = [];
            let spUserArray = [];
            if(Object.keys(companyUsers).length !== 0){
                Object.keys(companyUsers).map((d, index) => {
                    if(d in contracts){
                        let contactsObject = {}
                        let spUserObject = {}
                        contactsObject.id = d;
                        contactsObject.name = contracts[d];
                        contactsArray.push(contactsObject);

                        spUserObject.id = d;
                        spUserObject.name = contracts[d];
                        spUserArray.push(spUserObject);
                    }
                })
            }
            let recipientlistold = Store.getStoreData('recipientlistUpdate');
            for(let i=0;i<contactsArray.length;i++){
                recipientlistold.push(contactsArray[i])
            }
            let specificUsersList = [];
            for(let i=0;i<spUserArray.length;i++){
                specificUsersList.push(spUserArray[i])
            }

            let recipientlistnew = recipientlistold.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);} );
            let specificUsersListnew = specificUsersList.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);} );

            assign_data.additional_fields = additional_fields;

            this.setState({ Ref_ID:"Not Yet Generated", gc_company: update_gc_company, gc_companies: gc_companies,
                ready: true,
                companyID: cur_gc_id,
                cur_gc_id: cur_gc_id,
                is_gc: is_gc,due_date: due_date,recipientlist: recipientlistnew,giftTypeList: giftTypeList,
                currency: currencyValue !== "" ? currencyValue : Store.getStoreData('currencyDetails').currency,
                app_currency: currencyValue !== "" ? currencyValue : Store.getStoreData('currencyDetails').currency,
                // minvalue: minimumValue,
                fixedMinvalue: minimumValue,
                finalMinValueCheck: minimumValue,
                assign_data: assign_data,
                specificUsersList: specificUsersListnew});
            //console.log("this.generateRefID=",this.generateRefID())
        }
    }

    handleChangeCurGc = (evt) => {
        let gc_companies = Store.getStoreData('gc_companies');
        let gc_company = Store.getStoreData('gc_company');
        let is_gc = false;
        let companyUsers = {};
        for(let gc of gc_company) {
            //gc.id = (gc.id * 100000) + gc_company.id;
            if(parseInt(gc.id) === parseInt(evt.target.value)){
                is_gc = gc.is_gc;
                companyUsers = gc.users;
            }
        }

        let contracts = Store.getStoreData('contacts');
        let contactsArray = [];
        let spUserArray = [];
        if(Object.keys(companyUsers).length !== 0){
            Object.keys(companyUsers).map((d, index) => {
                if(d in contracts){
                    let contactsObject = {}
                    let spUserObject = {}
                    // contactsObject.id = contracts[d];
                    contactsObject.id = d;
                    contactsObject.name = contracts[d];
                    contactsArray.push(contactsObject);

                    spUserObject.id = d;
                    spUserObject.name = contracts[d];
                    spUserArray.push(spUserObject);
                }
            })
        }
        let recipientlist = Store.getStoreData('recipientlistUpdate');
        for(let i=0;i<contactsArray.length;i++){
            recipientlist.push(contactsArray[i])
        }
        let specificUsersList = [];
        for(let i=0;i<spUserArray.length;i++){
            specificUsersList.push(spUserArray[i])
        }
        // console.log("specificUsersList==>==>", specificUsersList)
        let recipientlistnew = recipientlist.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);} );
        let specificUsersListnew = specificUsersList.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);} );

        let cur_gc_id = parseInt(evt.target.value)
        let companyID = is_gc ? parseInt(gc_company[0].id) * 100000 + cur_gc_id : cur_gc_id;
        this.setState({ cur_gc_id: evt.target.value, is_gc: is_gc, companyID: companyID, recipientlist: recipientlistnew, specificUsersList: specificUsersListnew});
        // this.setState({ cur_gc_id: evt.target.value, is_gc: is_gc});
        //console.log("CURGCID", this.state.cur_gc_id);
    }

    handleChangeRecipient = (evt) => {
        console.log("evt==>", evt.target.value)
        if(evt.target.value != 'Other'){
        //    this.setState({recipientName: evt.target.value,recipientNameAll: evt.target.value,isOtherRecipientName: false})
           this.setState({recipientName: evt.target.value, isOtherRecipientName: false})
        }else{
            this.setState({isOtherRecipientName: true, recipientName: evt.target.value, recipientNameAll: ""})
        }
    }

    closeView = () => {
        let msg ='All data will be lost if you exit the screen, do you want to continue?'
        let title = 'Confirm'
        if (document.referrer.endsWith("/tmview")) {
            window.location =  process.env.REACT_APP_TMVIEW_URL;
        } else {
            console.log(Store.getStoreData('q_index'))
            if(Store.getStoreData('q_index') >= 0) {
                console.log('filterAlldatacheck',this.state.filterAlldatacheck)
                if(parseInt(this.state.cur_gc_id) !== this.state.filterAlldatacheck.cur_gc_id || this.state.recipientName !== this.state.filterAlldatacheck.recipientName || this.state.donorName !== this.state.filterAlldatacheck.donorName || this.state.giftTypeid.toString() !== this.state.filterAlldatacheck.giftTypeid.toString() || this.state.giftDescription !== this.state.filterAlldatacheck.giftDescription){
                    let alert_param = {title: title, message: msg, ok_text: 'Yes', cancel_text: 'No', confirm: true, alertHandler: this.closeModalAlert, stack: {id: 0}}
                    this.setState({alert_param: alert_param})
                //     if (window.confirm('All data will be lost if you exit the screen, do you want to continue?')){
                //         this.props.closeModal();
                //     }
                }else{
                    if(Store.getStoreData('isreferenceUrl') === true){
                        if(Store.getStoreData('isReferenceEmail') === true){
                            window.location = "/";
                        }
                    } else {
                        this.props.closeModal();
                    }
                }
            }else{
                if(parseInt(this.state.cur_gc_id) !== 0 || this.state.recipientName !== "" || this.state.donorName !== "" || this.state.giftTypeid.toString() !== "0" || this.state.giftDescription !== ""){
                    let alert_param = {title: title, message: msg, ok_text: 'Yes', cancel_text: 'No', confirm: true, alertHandler: this.closeModalAlert, stack: {id: 0}}
                    this.setState({alert_param: alert_param})
                    // if (window.confirm('All data will be lost if you exit the screen, do you want to continue?')){
                    //     this.props.closeModal();
                    // }
                }else{
                    if(Store.getStoreData('isreferenceUrl') === true){
                        if(Store.getStoreData('isReferenceEmail') === true){
                            window.location = "/";
                        }
                    } else {
                        this.props.closeModal();
                    }
                }
            }
        }
        //this.props.closeModal();
    }

    closeModalAlert = (result, stack) => {
        if(!result) {
            this.setState({alert_param: null})
            return
        }
        this.props.closeModal();
        this.setState({alert_param: null})
    }

    handleAdditionalROD = (event) => {
        console.log(event.target.value)
        const {additional_records_of_disclosure} = this.state;
        additional_records_of_disclosure.enabled = event.target.value;
        if(event.target.value === 'No'){
            additional_records_of_disclosure.description = "";
        }
        this.setState({ additional_records_of_disclosure })
    }
    handleThiredParty = (event) => {
        console.log(event.target.value)
        const {disclosure_to_third_party} = this.state;
        disclosure_to_third_party.enabled = event.target.value;
        if(event.target.value === 'No'){
            disclosure_to_third_party.description = "";
        }
        this.setState({ disclosure_to_third_party })
    }

    submitRegisterGift = (submit_form) => (event) => {
        event.preventDefault();
        let company_id = this.state.companyID;
        const  module_config = Store.getStoreData('module_config');
        if(submit_form === 1) {
            let problems = false
            let message = '<b>Please complete the following mandatory fields:</b><br>'
            if(this.state.cur_gc_id === 0){
                problems = true
                message += `Please select company.<br>`
            }
            if(this.state.giftTypeid.toString() === "0"){
                problems = true
                message += `Please select gift type.<br>`
            }
            if(this.state.isShowSpecificUsers && parseInt(this.state.specificUserID) === 0){
                problems = true
                message += `Please select a specific user.<br>`
            }
            if(this.state.recipientName === "" && this.state.giftStatus === 'received') {
                problems = true
                message += `Please select recipient name.<br>`
            }
            if(this.state.recipientName === "" && this.state.giftStatus === 'given'){
                problems = true
                message += `Please select given by name.<br>`
            }
            if(this.state.recipientName === "Other" && this.state.recipientNameAll === ""){
                problems = true
                message += `Please add name of other recipient.<br>`
            }
            if(this.state.giftStatus === "received" && this.state.donorName === ""){
                problems = true
                message += `Please add donor name.<br>`
            }
            if(this.state.isShowAppValue && (this.state.app_minvalue === "" || parseInt(this.state.app_minvalue) === 0)){
                problems = true
                message += `Enter approximate value.<br>`
            }
            if(this.state.giftDescription === ""){
                problems = true
                message += `Please provide a description of the gift/hospitality.<br>`
            }
            if(module_config.custom_fields.enabled){
                let fields = [];
                let is_numeric = false;
                let numericFields = [];
                if (module_config.custom_fields.additional_fields.length !== 0) {
                    this.state.assign_data.additional_fields.forEach((item) => {
                        if(item.displayFor === this.state.giftStatus){
                            if (item.required === true && item.value === "") {
                                fields.push(item.nickname);
                            }
                            if(item.value){
                                if(item.type === 'Numeric') {
                                    if(item.value.length > 0) {
                                        if(isNaN(item.value)){
                                            is_numeric = true;
                                            numericFields.push(item.nickname);
                                        }
                                    }
                                }
                            }
                        }
                        if(item.displayFor === 'both'){
                            if (item.required === true && item.value === "") {
                                fields.push(item.nickname);
                            }
                            if(item.value){
                                if(item.type === 'Numeric') {
                                    if(item.value.length > 0) {
                                        if(isNaN(item.value)){
                                            is_numeric = true;
                                            numericFields.push(item.nickname);
                                        }
                                    }
                                }
                            }
                        }
                    })
                }
                if (fields.length !== 0) {
                    let err_msg = "";
                    fields.forEach((item) => {
                        err_msg = err_msg + item + ".<br>";
                    })
                    err_msg = err_msg.substring(0, err_msg.length - 2);
                    // if (fields.length === 1) {
                    //     err_msg = err_msg + " field is mandatory. ";
                    // } else {
                    //     err_msg = err_msg + " fields are mandatory. ";
                    // }
                    problems = true
                    message += err_msg +`<br>`
                    // msg = err_msg
                    // let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.samealertremoveHandler, stack: {id: 0}}
                    // this.setState({alert_param: alert_param})
                    // return;
                }
                if(is_numeric) {
                    let err_msg_new = "";
                    numericFields.forEach((item) => {
                        err_msg_new = err_msg_new + item + ", ";
                    })
                    err_msg_new = err_msg_new.substring(0, err_msg_new.length - 2);
                    if (numericFields.length === 1) {
                        err_msg_new = "<br>Please put a numeric value in the "+ err_msg_new + " field. ";
                    } else {
                        err_msg_new = "<br>Please put numeric values in "+ err_msg_new + " fields. ";
                    }
                    problems = true
                    message += err_msg_new +`<br>`
                    // msg = err_msg_new
                    // let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.samealertremoveHandler, stack: {id: 0}}
                    // this.setState({alert_param: alert_param})
                    // return;
                }
            }

            if(problems) {
                let alert_param = {title: 'Warning', message: message, ok_text: 'OK', confirm: false,
                           alertHandler: this.samealertremoveHandler, stack: {}, align: 'left'}
                this.setState({alert_param: alert_param})
                return;
            }
            console.log("this.state.finalMinValueCheck===>", this.state.finalMinValueCheck)
            let minvaluecheck = this.state.minvalue;
            if(this.state.isShowAppValue){
                minvaluecheck = this.state.app_minvalue;
            }

            // console.log("minvalue===>", this.state.minvalue)

            // console.log("minvaluecheck===>", parseFloat(minvaluecheck))
            // console.log("finalMinValueCheck===>", parseInt(this.state.finalMinValueCheck))

            // console.log(Math.round(parseFloat(minvaluecheck)));
            // console.log(Math.round(parseFloat(this.state.finalMinValueCheck)));
            // console.log("Check===>", parseFloat(minvaluecheck) < parseFloat(this.state.finalMinValueCheck))
            // return false;
            if(parseFloat(minvaluecheck) < parseFloat(this.state.finalMinValueCheck)){
                if('is_show_decl_msg' in module_config.general){
                    if(module_config.general.is_show_decl_msg === 1){
                        let alert_param = {title: 'Alert', message: 'This value is less than the minimum value of reportable gifts/hospitality. Do you want to proceed?', ok_text: 'OK', cancel_text: 'Cancel', confirm: true,
                        alertHandler: this.processConfirmationAddNew, stack: {submit_form: submit_form}}
                        this.setState({alert_param: alert_param})
                    }else{
                        this.processConfirmationAddNew(true, {submit_form: submit_form})
                    }
                }else{
                    let alert_param = {title: 'Alert', message: 'This value is less than the minimum value of reportable gifts/hospitality. Do you want to proceed?', ok_text: 'OK', cancel_text: 'Cancel', confirm: true,
                    alertHandler: this.processConfirmationAddNew, stack: {submit_form: submit_form}}
                    this.setState({alert_param: alert_param})
                }
            }else{

                let minvaluecheck = this.state.minvalue;
                if(this.state.isShowAppValue){
                    minvaluecheck = this.state.app_minvalue;
                }

                let approval_level = "none";
                let isenable_approval_su = false
                let specificUserID_app_level = [];
                if(module_config.approval_level.enable_enhanced_approval && module_config.approval_level.enable_approval_levels === false){
                    let giftTypes = module_config.gift_types;
                    for(let d of giftTypes){
                        if(d.isActive === 1 && parseInt(d.giftTypeID) === parseInt(this.state.giftTypeid)){
                            approval_level = 'approver_level' in d ? d.approver_level : "none";
                        }
                    }
                }else if(module_config.approval_level.enable_enhanced_approval && module_config.approval_level.enable_approval_levels && module_config.approval_level.applies_to_all_gift_types){
                    let approval = module_config.approval_level.approval[0].elem
                    let app_level = ''
                    for(let d of approval){
                        if(this.state.giftStatus === 'given'){
                            if(d.status && parseInt(d.givenApproval.value) <= Math.round(parseFloat(minvaluecheck))){
                                app_level = d.level
                            }
                        }
                        if(this.state.giftStatus === 'received'){
                            if(d.status && parseInt(d.receivedApproval.value) <= Math.round(parseFloat(minvaluecheck))){
                                app_level = d.level
                            }
                        }
                    }
                    console.log("app_level==>", app_level)
                    let approval_level_new = "";
                    for(let d of approval){
                        if(this.state.giftStatus === 'given' && d.status && d.level === app_level){
                            let approvers = d.givenApproval.approvers;
                            let i = 0;
                            if(d.givenApproval.approver_type !== 'Specific User(s)'){
                                for(let k of approvers){
                                    approval_level_new += k.id;
                                    if(i < approvers.length - 1) {
                                        approval_level_new += '/'
                                    }
                                    i++
                                }
                            }else{
                                approval_level_new = 'su';
                                isenable_approval_su = true
                                for(let k of approvers){
                                    specificUserID_app_level.push(parseInt(k.id))
                                }
                            }
                        }
                        if(this.state.giftStatus === 'received' && d.status && d.level === app_level){
                            let approvers = d.receivedApproval.approvers;
                            let i = 0;
                            if(d.receivedApproval.approver_type !== 'Specific User(s)'){
                                for(let k of approvers){
                                    approval_level_new += k.id;
                                    if(i < approvers.length - 1) {
                                        approval_level_new += '/'
                                    }
                                    i++
                                }
                            }else{
                                approval_level_new = 'su';
                                isenable_approval_su = true
                                for(let k of approvers){
                                    specificUserID_app_level.push(parseInt(k.id))
                                }
                            }
                        }
                    }
                    console.log("approval_level_new===>", approval_level_new)
                    approval_level = approval_level_new
                }else if(module_config.approval_level.enable_enhanced_approval && module_config.approval_level.enable_approval_levels && module_config.approval_level.applies_to_all_gift_types === false){
                    let approval_giftTypes = module_config.approval_level.approval_giftTypes
                    let typeOfApproval = [];
                    for(let d of approval_giftTypes){
                        if(parseInt(d.giftTypeID) === parseInt(this.state.giftTypeid)){
                            typeOfApproval = d.elem;
                        }
                    }

                    let app_level = ''
                    for(let d of typeOfApproval){
                        if(this.state.giftStatus === 'given'){
                            if(d.status && parseInt(d.givenApproval.value) <= Math.round(parseFloat(minvaluecheck))){
                                app_level = d.level
                            }
                        }
                        if(this.state.giftStatus === 'received'){
                            if(d.status && parseInt(d.receivedApproval.value) <= Math.round(parseFloat(minvaluecheck))){
                                app_level = d.level
                            }
                        }
                    }
                    let approval_level_new = "";
                    for(let d of typeOfApproval){
                        if(this.state.giftStatus === 'given' && d.status && d.level === app_level){
                            let approvers = d.givenApproval.approvers;
                            let i = 0;
                            if(d.givenApproval.approver_type !== 'Specific User(s)'){
                                for(let k of approvers){
                                    approval_level_new += k.id;
                                    if(i < approvers.length - 1) {
                                        approval_level_new += '/'
                                    }
                                    i++
                                }
                            }else{
                                approval_level_new = 'su';
                                isenable_approval_su = true
                                for(let k of approvers){
                                    specificUserID_app_level.push(parseInt(k.id))
                                }
                            }
                        }
                        if(this.state.giftStatus === 'received' && d.status && d.level === app_level){
                            let approvers = d.receivedApproval.approvers;
                            let i = 0;
                            if(d.receivedApproval.approver_type !== 'Specific User(s)'){
                                for(let k of approvers){
                                    approval_level_new += k.id;
                                    if(i < approvers.length - 1) {
                                        approval_level_new += '/'
                                    }
                                    i++
                                }
                            }else{
                                approval_level_new = 'su';
                                isenable_approval_su = true
                                for(let k of approvers){
                                    specificUserID_app_level.push(parseInt(k.id))
                                }
                            }
                        }
                    }
                    console.log("approval_level_new===>", approval_level_new)
                    approval_level = approval_level_new === '' ? 'none' : approval_level_new
                }

                // let giftTypes = module_config.gift_types;
                // for(let d of giftTypes){
                //     if(d.isActive === 1 && parseInt(d.giftTypeID) === parseInt(this.state.giftTypeid)){
                //         approval_level = 'approver_level' in d ? d.approver_level : "none";
                //     }
                // }
                let repNameAll = this.state.recipientNameAll
                let contracts = Store.getStoreData('contacts');
                let reciname = this.state.recipientName;
                let check_recipient_id = 0
                if(this.state.recipientName !== 'Other'){
                    repNameAll = contracts[this.state.recipientName]
                    reciname = contracts[this.state.recipientName]
                    check_recipient_id = this.state.recipientName;
                }

                let specificUserID = this.state.specificUserID
                let isUserlevelApproval = false
                // Skip Two level approval start
                let pre_app_level = ""
                if(approval_level === 'ar-lm' || approval_level === 'lm-ar'){
                    if('user_level_approval' in module_config && check_recipient_id.toString() in module_config.user_level_approval){
                        let user_level_approval = module_config.user_level_approval[check_recipient_id.toString()]
                        pre_app_level = approval_level;
                        approval_level = user_level_approval.app_req_id;

                        if(user_level_approval.app_req_id === 'su'){
                            if('su_user' in user_level_approval && Object.keys(user_level_approval.su_user).length !== 0){
                                specificUserID = user_level_approval.su_user.value
                                isUserlevelApproval = true
                            }
                        }
                    }
                }
                // Skip Two level approval end
                // Skip One level approval start
                if(approval_level === 'ar' || approval_level === 'lm'){
                    if('approval_level' in module_config && module_config.approval_level.enable_enhanced_approval === true && module_config.approval_level.enable_approval_levels === false){
                        if('user_level_approval' in module_config && check_recipient_id.toString() in module_config.user_level_approval){
                            let user_level_approval = module_config.user_level_approval[check_recipient_id.toString()]
                            pre_app_level = approval_level;
                            approval_level = user_level_approval.app_req_id;

                            if(user_level_approval.app_req_id === 'su'){
                                if('su_user' in user_level_approval && Object.keys(user_level_approval.su_user).length !== 0){
                                    specificUserID = user_level_approval.su_user.value
                                    isUserlevelApproval = true
                                }
                            }
                        }
                    }
                }
                // Skip One level approval end

                let headerinfo = {};
                headerinfo.gift_status = this.state.giftStatus;
                // headerinfo.recipient_name = this.state.recipientNameAll;
                headerinfo.recipient_name = repNameAll;
                headerinfo.donor_name = this.state.donorName;
                headerinfo.gift_typeid = parseInt(this.state.giftTypeid);
                headerinfo.currency = this.state.currency;
                headerinfo.minvalue = this.state.minvalue;
                headerinfo.gift_description = this.state.giftDescription;
                // headerinfo.check_recipient_value = this.state.recipientName;
                headerinfo.check_recipient_value = reciname;
                headerinfo.check_recipient_id = check_recipient_id;
                headerinfo.giftedDate = this.state.giftedDate;
                headerinfo.additional_fields = this.state.assign_data.additional_fields;
                headerinfo.approval_level = approval_level;
                headerinfo.pre_app_level = pre_app_level;
                headerinfo.specificUserID = specificUserID;
                headerinfo.specificUserID_app_level = specificUserID_app_level;
                headerinfo.isenable_approval_su = isenable_approval_su;
                headerinfo.giftGivenTo = this.state.giftGivenTo;
                headerinfo.app_currency = this.state.app_currency;
                headerinfo.app_minvalue = this.state.app_minvalue;
                headerinfo.isShowAppValue = this.state.isShowAppValue;
                headerinfo.isUserlevelApproval = isUserlevelApproval;

                if (approval_level === "nr") {
                    submit_form = 2
                    headerinfo.approval_type = 'Not required (Auto-approve)';
                    headerinfo.approval_description = '';
                }

                let final_object_data = {};
                final_object_data.headerinfo = headerinfo;

                let curmonth = parseInt(this.state.due_date.getMonth());
                let currmonth = curmonth + 1;
                var dueDateStr = currmonth >= 10 ? this.state.due_date.getFullYear()+'/'+currmonth+'/' : this.state.due_date.getFullYear()+'/0'+currmonth+'/';
                dueDateStr += this.state.due_date.getDate() < 10 ? '0' + this.state.due_date.getDate() : this.state.due_date.getDate();

                let bin_files = this.state.cur_files_json === null ? this.state.binFiles : [...this.state.binFiles, ...this.state.cur_files_json];
                // console.log("bin_files==>",bin_files)

                //new email to su and lm
                let apov_lvl = approval_level;
                let aplv = apov_lvl.split("/")
                if(apov_lvl === 'lm-ar'){
                    apov_lvl = 'lm'
                }else if(aplv.length !== 0){
                    if(aplv.length === 1){
                        apov_lvl = aplv[0]
                    }else if(aplv.length === 3){
                        apov_lvl = 'amgmlm'
                    }else{
                        aplv.sort();
                        let cval = '';
                        for(let d of aplv){
                            cval += d.toString()
                        }
                        apov_lvl = cval
                    }
                    // for(let d of aplv){
                    //     if(d.toString() === 'lm'){
                    //         apov_lvl = 'lm'
                    //     }
                    //     if(d.toString() === 'su'){
                    //         apov_lvl = 'su'
                    //     }
                    // }
                }
                console.log("apov_lvl===>", apov_lvl)
                //new email to su and lm

                let task_json = {
                    "task_data": { "module_id": "gifts", "last_action": "COMP_GIFTS_REGISTER", "actiondate": Date.now(),
                    "cur_assigned_to" : 0, 'task_type' : 'COMP_GIFTS', 'parent_task_id' : 0,'due_date' : dueDateStr,
                    'group_company_id': this.state.cur_gc_id},
                    "object_data": final_object_data, "group_company_id": company_id,
                    "action_data": { "action": "COMP_GIFTS_REGISTER", "actiondate": Date.now(), "assigned_to": 0, "cur_lane":"COMP_GIFTS_REGISTER","performed_by": 4616 }
                };

                let postData = {command: "create_gifts_task", task_json: task_json,bin_files: bin_files, submit: submit_form, role: Store.getStoreData('role'),
                                is_gc: this.state.is_gc, gc_company_id: this.state.cur_gc_id, apov_lvl: apov_lvl
                            };
                console.log("postData",postData)
                let api = new APICall();
                api.command(postData, this.processGiftsData);
                this.setState({submit_form,is_disabled: true, loading: true})
            }
        } else {
            let userrole = Store.getStoreData('role');
            if(userrole === 'gm' && this.state.cur_gc_id === 0){
                // alert('Please select company.');
                let alert_param = {title: 'Warning', message: 'Please select company.', ok_text: 'OK', confirm: false, alertHandler: this.samealertremoveHandler, stack: {id: 0}}
                this.setState({alert_param: alert_param})
                return
            }

            let minvaluecheck = this.state.minvalue;
            if(this.state.isShowAppValue){
                minvaluecheck = this.state.app_minvalue;
            }

            let approval_level = "none";
            let isenable_approval_su = false
            let specificUserID_app_level = [];
            if(module_config.approval_level.enable_enhanced_approval && module_config.approval_level.enable_approval_levels === false){
                let giftTypes = module_config.gift_types;
                for(let d of giftTypes){
                    if(d.isActive === 1 && parseInt(d.giftTypeID) === parseInt(this.state.giftTypeid)){
                        approval_level = 'approver_level' in d ? d.approver_level : "none";
                    }
                }
            }else if(module_config.approval_level.enable_enhanced_approval && module_config.approval_level.enable_approval_levels && module_config.approval_level.applies_to_all_gift_types){
                let approval = module_config.approval_level.approval[0].elem
                let app_level = ''
                for(let d of approval){
                    if(this.state.giftStatus === 'given'){
                        if(d.status && parseInt(d.givenApproval.value) <= Math.round(parseFloat(minvaluecheck))){
                            app_level = d.level
                        }
                    }
                    if(this.state.giftStatus === 'received'){
                        if(d.status && parseInt(d.receivedApproval.value) <= Math.round(parseFloat(minvaluecheck))){
                            app_level = d.level
                        }
                    }
                }
                let approval_level_new = "";
                for(let d of approval){
                    if(this.state.giftStatus === 'given' && d.status && d.level === app_level){
                        let approvers = d.givenApproval.approvers;
                        let i = 0;
                        if(d.givenApproval.approver_type !== 'Specific User(s)'){
                            for(let k of approvers){
                                approval_level_new += k.id;
                                if(i < approvers.length - 1) {
                                    approval_level_new += '/'
                                }
                                i++
                            }
                        }else{
                            approval_level_new = 'su';
                            isenable_approval_su = true
                            for(let k of approvers){
                                specificUserID_app_level.push(parseInt(k.id))
                            }
                        }
                    }
                    if(this.state.giftStatus === 'received' && d.status && d.level === app_level){
                        let approvers = d.receivedApproval.approvers;
                        let i = 0;
                        if(d.receivedApproval.approver_type !== 'Specific User(s)'){
                            for(let k of approvers){
                                approval_level_new += k.id;
                                if(i < approvers.length - 1) {
                                    approval_level_new += '/'
                                }
                                i++
                            }
                        }else{
                            approval_level_new = 'su';
                            isenable_approval_su = true
                            for(let k of approvers){
                                specificUserID_app_level.push(parseInt(k.id))
                            }
                        }
                    }
                }
                console.log("approval_level_new===>", approval_level_new)
                approval_level = approval_level_new
            }else if(module_config.approval_level.enable_enhanced_approval && module_config.approval_level.enable_approval_levels && module_config.approval_level.applies_to_all_gift_types === false){
                let approval_giftTypes = module_config.approval_level.approval_giftTypes
                let typeOfApproval = [];
                for(let d of approval_giftTypes){
                    if(parseInt(d.giftTypeID) === parseInt(this.state.giftTypeid)){
                        typeOfApproval = d.elem;
                    }
                }

                let app_level = ''
                for(let d of typeOfApproval){
                    if(this.state.giftStatus === 'given'){
                        if(d.status && parseInt(d.givenApproval.value) <= Math.round(parseFloat(minvaluecheck))){
                            app_level = d.level
                        }
                    }
                    if(this.state.giftStatus === 'received'){
                        if(d.status && parseInt(d.receivedApproval.value) <= Math.round(parseFloat(minvaluecheck))){
                            app_level = d.level
                        }
                    }
                }
                let approval_level_new = "";
                for(let d of typeOfApproval){
                    if(this.state.giftStatus === 'given' && d.status && d.level === app_level){
                        let approvers = d.givenApproval.approvers;
                        let i = 0;
                        if(d.givenApproval.approver_type !== 'Specific User(s)'){
                            for(let k of approvers){
                                approval_level_new += k.id;
                                if(i < approvers.length - 1) {
                                    approval_level_new += '/'
                                }
                                i++
                            }
                        }else{
                            approval_level_new = 'su';
                            isenable_approval_su = true
                            for(let k of approvers){
                                specificUserID_app_level.push(parseInt(k.id))
                            }
                        }
                    }
                    if(this.state.giftStatus === 'received' && d.status && d.level === app_level){
                        let approvers = d.receivedApproval.approvers;
                        let i = 0;
                        if(d.receivedApproval.approver_type !== 'Specific User(s)'){
                            for(let k of approvers){
                                approval_level_new += k.id;
                                if(i < approvers.length - 1) {
                                    approval_level_new += '/'
                                }
                                i++
                            }
                        }else{
                            approval_level_new = 'su';
                            isenable_approval_su = true
                            for(let k of approvers){
                                specificUserID_app_level.push(parseInt(k.id))
                            }
                        }
                    }
                }
                console.log("approval_level_new===>", approval_level_new)
                approval_level = approval_level_new === '' ? 'none' : approval_level_new
            }

            let repNameAll = this.state.recipientNameAll
            let contracts = Store.getStoreData('contacts');
            let reciname = this.state.recipientName;
            let check_recipient_id = 0
            if(this.state.recipientName !== 'Other'){
                repNameAll = contracts[this.state.recipientName]
                reciname = contracts[this.state.recipientName]
                check_recipient_id = this.state.recipientName;
            }

            let headerinfo = {};
            headerinfo.gift_status = this.state.giftStatus;
            // headerinfo.recipient_name = this.state.recipientNameAll;
            headerinfo.donor_name = this.state.donorName;
            headerinfo.gift_typeid = parseInt(this.state.giftTypeid);
            headerinfo.currency = this.state.currency;
            headerinfo.minvalue = this.state.minvalue;
            headerinfo.gift_description = this.state.giftDescription;
            // headerinfo.check_recipient_value = this.state.recipientName;
            headerinfo.recipient_name = repNameAll;
            headerinfo.check_recipient_value = reciname;
            headerinfo.check_recipient_id = check_recipient_id;
            headerinfo.giftedDate = this.state.giftedDate;
            headerinfo.additional_fields = this.state.assign_data.additional_fields;
            headerinfo.approval_level = approval_level;
            headerinfo.specificUserID = this.state.specificUserID;
            headerinfo.specificUserID_app_level = specificUserID_app_level;
            headerinfo.isenable_approval_su = isenable_approval_su;
            headerinfo.giftGivenTo = this.state.giftGivenTo;
            headerinfo.app_currency = this.state.app_currency;
            headerinfo.app_minvalue = this.state.app_minvalue;
            headerinfo.isShowAppValue = this.state.isShowAppValue;

            let final_object_data = {};
            final_object_data.headerinfo = headerinfo;
            let curmonth = parseInt(this.state.due_date.getMonth());
            let currmonth = curmonth + 1;
            var dueDateStr = currmonth >= 10 ? this.state.due_date.getFullYear()+'/'+currmonth+'/' : this.state.due_date.getFullYear()+'/0'+currmonth+'/';
            dueDateStr += this.state.due_date.getDate() < 10 ? '0' + this.state.due_date.getDate() : this.state.due_date.getDate();

            let bin_files = this.state.cur_files_json === null ? this.state.binFiles : [...this.state.binFiles, ...this.state.cur_files_json];
            console.log("bin_files==>",bin_files)

            let task_json = {
                "task_data": { "module_id": "gifts", "last_action": "COMP_GIFTS_REGISTER", "actiondate": Date.now(),
                "cur_assigned_to" : 0, 'task_type' : 'COMP_GIFTS', 'parent_task_id' : 0,'due_date' : dueDateStr, 'group_company_id': this.state.cur_gc_id},
                "object_data": final_object_data,"group_company_id": company_id,
                "action_data": { "action": "COMP_GIFTS_REGISTER", "actiondate": Date.now(), "assigned_to": 0, "cur_lane":"COMP_GIFTS_REGISTER","performed_by": 4616 }
            };
            // console.log("task jason", JSON.stringify(task_json));
            let postData = {command: "create_gifts_task", task_json: task_json,bin_files: bin_files, submit: submit_form,
            is_gc: this.state.is_gc,
            gc_company_id: this.state.cur_gc_id };
            console.log("postData",postData)

            let api = new APICall();
            api.command(postData, this.processGiftsData);
            this.setState({submit_form,is_disabled: true, loading: true})
        }
    }

    processConfirmationAddNew = (result, stack) => {
        if(!result) {
            this.setState({alert_param: null, giftStatus: "received",recipientName: "",recipientNameAll: "",donorName: "",giftTypeid: 0,giftDescription: ""})
            return
        }
        this.setState({alert_param: null})
        let company_id = this.state.companyID;
        const  module_config = Store.getStoreData('module_config');
        let submit_form = stack.submit_form
        let approval_level = "none";
        if('enable_auto_approval' in module_config.general && module_config.general.enable_auto_approval){
            approval_level = 'aa'
        }else if('enable_auto_approval' in module_config.general && module_config.general.enable_auto_approval === false){
            if(module_config.approval_level.enable_enhanced_approval && module_config.approval_level.enable_approval_levels === false){
                let giftTypes = module_config.gift_types;
                for(let d of giftTypes){
                    if(d.isActive === 1 && parseInt(d.giftTypeID) === parseInt(this.state.giftTypeid)){
                        approval_level = d.approver_level;
                    }
                }
            }
            // else if(module_config.approval_level.enable_enhanced_approval && module_config.approval_level.enable_approval_levels && module_config.approval_level.applies_to_all_gift_types){
            //     if(module_config.approval_level.approval[0].elem.length !== 0){
            //         approval_level = 'nr'
            //     }
            // }else if(module_config.approval_level.enable_enhanced_approval && module_config.approval_level.enable_approval_levels && module_config.approval_level.applies_to_all_gift_types === false){
            //     for(let d of module_config.approval_level.approval_giftTypes){
            //         if(parseInt(d.giftTypeID) === parseInt(this.state.giftTypeid)){
            //             approval_level = 'nr'
            //         }
            //     }
            // }
        }

        let repNameAll = this.state.recipientNameAll
        let contracts = Store.getStoreData('contacts');
        let reciname = this.state.recipientName;
        let check_recipient_id = 0
        if(this.state.recipientName !== 'Other'){
            repNameAll = contracts[this.state.recipientName]
            reciname = contracts[this.state.recipientName]
            check_recipient_id = this.state.recipientName;
        }


        let headerinfo = {};
        headerinfo.gift_status = this.state.giftStatus;
        // headerinfo.recipient_name = this.state.recipientNameAll;
        headerinfo.donor_name = this.state.donorName;
        headerinfo.gift_typeid = parseInt(this.state.giftTypeid);
        headerinfo.currency = this.state.currency;
        headerinfo.minvalue = this.state.minvalue;
        headerinfo.gift_description = this.state.giftDescription;
        // headerinfo.check_recipient_value = this.state.recipientName;
        headerinfo.recipient_name = repNameAll;
        headerinfo.check_recipient_value = reciname;
        headerinfo.check_recipient_id = check_recipient_id;
        headerinfo.giftedDate = this.state.giftedDate;
        headerinfo.additional_fields = this.state.assign_data.additional_fields;
        headerinfo.approval_level = approval_level;
        headerinfo.specificUserID = this.state.specificUserID;
        headerinfo.specificUserID_app_level = [];
        headerinfo.isenable_approval_su = false;
        headerinfo.giftGivenTo = this.state.giftGivenTo;
        headerinfo.app_currency = this.state.app_currency;
        headerinfo.app_minvalue = this.state.app_minvalue;
        headerinfo.isShowAppValue = this.state.isShowAppValue;

        if (approval_level === "nr") {
            submit_form = 2
            headerinfo.approval_type = 'Not required (Auto-approve)';
            headerinfo.approval_description = '';
        }
        if (approval_level === "aa") {
            submit_form = 2
            headerinfo.approval_type = 'Auto approved';
            headerinfo.approval_description = '';
        }

        let final_object_data = {};
        final_object_data.headerinfo = headerinfo;

        let curmonth = parseInt(this.state.due_date.getMonth());
        let currmonth = curmonth + 1;
        var dueDateStr = currmonth >= 10 ? this.state.due_date.getFullYear()+'/'+currmonth+'/' : this.state.due_date.getFullYear()+'/0'+currmonth+'/';
        dueDateStr += this.state.due_date.getDate() < 10 ? '0' + this.state.due_date.getDate() : this.state.due_date.getDate();

        let bin_files = this.state.cur_files_json === null ? this.state.binFiles : [...this.state.binFiles, ...this.state.cur_files_json];
        // console.log("bin_files==>",bin_files)

        let task_json = {
            "task_data": { "module_id": "gifts", "last_action": "COMP_GIFTS_REGISTER", "actiondate": Date.now(),
            "cur_assigned_to" : 0, 'task_type' : 'COMP_GIFTS', 'parent_task_id' : 0,'due_date' : dueDateStr,
            'group_company_id': this.state.cur_gc_id},
            "object_data": final_object_data, "group_company_id": company_id,
            "action_data": { "action": "COMP_GIFTS_REGISTER", "actiondate": Date.now(), "assigned_to": 0, "cur_lane":"COMP_GIFTS_REGISTER","performed_by": 4616 }
        };
        //console.log("task jason", JSON.stringify(task_json));
        let postData = {command: "create_gifts_task", task_json: task_json,bin_files: bin_files, submit: submit_form, role: Store.getStoreData('role'),
                        is_gc: this.state.is_gc,
                        gc_company_id: this.state.cur_gc_id
                    };
        console.log("postData",postData)
        let api = new APICall();
        api.command(postData, this.processGiftsData);
        this.setState({submit_form, is_disabled: true, loading: true})
    }

    alertremoveHandler = (result,stack) => {
        this.setState({alert_param: null})
        window.location.reload();
    }

    samealertremoveHandler = (result,stack) => {
        this.setState({alert_param: null})
    }

    processGiftsData = (result) => {
        console.log("result", result)
        this.setState({loading: false})
        let msg = '';
        let title = 'Alert';
        if(this.state.submit_form === 1){
            msg = 'Gifts registered successfully.'
            // alert('Gifts registered successfully.');
            let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.alertremoveHandler, stack: {id: 0}}
            this.setState({alert_param: alert_param})
        }else if(this.state.submit_form === 2){
            let alert_param = {title: title, message: 'Gifts added successfully.', ok_text: 'OK', confirm: false, alertHandler: this.alertremoveHandler, stack: {id: 0}}
            this.setState({alert_param: alert_param})
        }else {
            msg = 'The gift has been saved. It is available to be modified and added to the register.'
            // alert('The gift has been saved. It is available to be modified and added to the register.');
            let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.alertremoveHandler, stack: {id: 0}}
            this.setState({alert_param: alert_param})
        }
        // window.location.reload();
    }

    updateRegisterGift = (submit_form) => (event) => {
        event.preventDefault();
        let company_id = this.state.companyID;
        const  module_config = Store.getStoreData('module_config');
        if(submit_form === 1) {
            let problems = false
            let message = '<b>Please complete the following mandatory fields:</b><br>'
            if(this.state.cur_gc_id === 0){
                problems = true
                message += `Please select company.<br>`
            }
            if(this.state.recipientName === "" && this.state.giftStatus === 'received') {
                problems = true
                message += `Please select recipient name.<br>`
            }
            if(this.state.recipientName === "" && this.state.giftStatus === 'given'){
                problems = true
                message += `Please select given by name.<br>`
            }
            if(this.state.recipientName === "Other" && this.state.recipientNameAll === ""){
                problems = true
                message += `Please add name of other recipient.<br>`
            }
            if(this.state.giftStatus === "received" && this.state.donorName === ""){
                problems = true
                message += `Please add donor name.<br>`
            }
            if(this.state.isShowAppValue && (this.state.app_minvalue === "" || parseInt(this.state.app_minvalue) === 0)){
                problems = true
                message += `Enter approximate value.<br>`
            }
            if(this.state.giftTypeid.toString() === "0"){
                problems = true
                message += `Please select gift type.<br>`
            }
            if(this.state.isShowSpecificUsers && parseInt(this.state.specificUserID) === 0){
                problems = true
                message += `Please select a specific user.<br>`
            }
            if(this.state.giftDescription === ""){
                problems = true
                message += `Please provide a description of the gift/hospitality.<br>`
            }
            if(module_config.custom_fields.enabled){
                let fields = [];
                let is_numeric = false;
                let numericFields = [];
                if (module_config.custom_fields.additional_fields.length !== 0) {
                    this.state.assign_data.additional_fields.forEach((item) => {
                        if(item.displayFor === this.state.giftStatus){
                            if (item.required === true && item.value === "") {
                                fields.push(item.nickname);
                            }
                            if(item.value){
                                if(item.type === 'Numeric') {
                                    if(item.value.length > 0) {
                                        if(isNaN(item.value)){
                                            is_numeric = true;
                                            numericFields.push(item.nickname);
                                        }
                                    }
                                }
                            }
                        }
                        if(item.displayFor === 'both'){
                            if (item.required === true && item.value === "") {
                                fields.push(item.nickname);
                            }
                            if(item.value){
                                if(item.type === 'Numeric') {
                                    if(item.value.length > 0) {
                                        if(isNaN(item.value)){
                                            is_numeric = true;
                                            numericFields.push(item.nickname);
                                        }
                                    }
                                }
                            }
                        }
                    })
                }
                if (fields.length !== 0) {
                    let err_msg = "";
                    fields.forEach((item) => {
                        err_msg = err_msg + item + ".<br>";
                    })
                    err_msg = err_msg.substring(0, err_msg.length - 2);
                    // if (fields.length === 1) {
                    //     err_msg = err_msg + " field is mandatory. ";
                    // } else {
                    //     err_msg = err_msg + " fields are mandatory. ";
                    // }
                    problems = true
                    message += err_msg +`<br>`
                    // msg = err_msg
                    // let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.samealertremoveHandler, stack: {id: 0}}
                    // this.setState({alert_param: alert_param})
                    // return;
                }
                if(is_numeric) {
                    let err_msg_new = "";
                    numericFields.forEach((item) => {
                        err_msg_new = err_msg_new + item + ", ";
                    })
                    err_msg_new = err_msg_new.substring(0, err_msg_new.length - 2);
                    if (numericFields.length === 1) {
                        err_msg_new = "<br>Please put the numeric value in the "+ err_msg_new + " field. ";
                    } else {
                        err_msg_new = "<br>Please put numeric values in "+ err_msg_new + " fields. ";
                    }
                    problems = true
                    message += err_msg_new +`<br>`
                    // msg = err_msg_new
                    // let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.samealertremoveHandler, stack: {id: 0}}
                    // this.setState({alert_param: alert_param})
                    // return;
                }
            }

            if(problems) {
                let alert_param = {title: 'Warning', message: message, ok_text: 'OK', confirm: false,
                           alertHandler: this.samealertremoveHandler, stack: {}, align: 'left'}
                this.setState({alert_param: alert_param})
                return;
            }

            // if(parseInt(this.state.minvalue) < parseInt(Store.getStoreData('module_config').general.companyCurrencyDetails.minvalue)){
            let minvaluecheck = this.state.minvalue;
            if(this.state.isShowAppValue){
                minvaluecheck = this.state.app_minvalue;
            }
            if(parseFloat(minvaluecheck) < parseFloat(this.state.finalMinValueCheck)){
                if('is_show_decl_msg' in module_config.general){
                    if(module_config.general.is_show_decl_msg === 1){
                        let alert_param = {title: 'Alert', message: 'This value is less than the minimum value of reportable gifts/hospitality. Do you want to proceed?', ok_text: 'OK', cancel_text: 'Cancel', confirm: true,
                        alertHandler: this.processConfirmationUpdate, stack: {submit_form: submit_form}}
                        this.setState({alert_param: alert_param})
                    }else{
                        this.processConfirmationUpdate(true, {submit_form: submit_form})
                    }
                }else{
                    let alert_param = {title: 'Alert', message: 'This value is less than the minimum value of reportable gifts/hospitality. Do you want to proceed?', ok_text: 'OK', cancel_text: 'Cancel', confirm: true,
                    alertHandler: this.processConfirmationUpdate, stack: {submit_form: submit_form}}
                    this.setState({alert_param: alert_param})
                }
            }else{
                let minvaluecheck = this.state.minvalue;
                if(this.state.isShowAppValue){
                    minvaluecheck = this.state.app_minvalue;
                }

                let approval_level = "none";
                let isenable_approval_su = false
                let specificUserID_app_level = [];
                if(module_config.approval_level.enable_enhanced_approval && module_config.approval_level.enable_approval_levels === false){
                    let giftTypes = module_config.gift_types;
                    for(let d of giftTypes){
                        if(d.isActive === 1 && parseInt(d.giftTypeID) === parseInt(this.state.giftTypeid)){
                            approval_level = 'approver_level' in d ? d.approver_level : "none";
                        }
                    }
                }else if(module_config.approval_level.enable_enhanced_approval && module_config.approval_level.enable_approval_levels && module_config.approval_level.applies_to_all_gift_types){
                    let approval = module_config.approval_level.approval[0].elem
                    let app_level = ''
                    for(let d of approval){
                        if(this.state.giftStatus === 'given'){
                            if(d.status && parseInt(d.givenApproval.value) <= Math.round(parseFloat(minvaluecheck))){
                                app_level = d.level
                            }
                        }
                        if(this.state.giftStatus === 'received'){
                            if(d.status && parseInt(d.receivedApproval.value) <= Math.round(parseFloat(minvaluecheck))){
                                app_level = d.level
                            }
                        }
                    }
                    let approval_level_new = "";
                    for(let d of approval){
                        if(this.state.giftStatus === 'given' && d.status && d.level === app_level){
                            let approvers = d.givenApproval.approvers;
                            let i = 0;
                            if(d.givenApproval.approver_type !== 'Specific User(s)'){
                                for(let k of approvers){
                                    approval_level_new += k.id;
                                    if(i < approvers.length - 1) {
                                        approval_level_new += '/'
                                    }
                                    i++
                                }
                            }else{
                                approval_level_new = 'su';
                                isenable_approval_su = true
                                for(let k of approvers){
                                    specificUserID_app_level.push(parseInt(k.id))
                                }
                            }
                        }
                        if(this.state.giftStatus === 'received' && d.status && d.level === app_level){
                            let approvers = d.receivedApproval.approvers;
                            let i = 0;
                            if(d.receivedApproval.approver_type !== 'Specific User(s)'){
                                for(let k of approvers){
                                    approval_level_new += k.id;
                                    if(i < approvers.length - 1) {
                                        approval_level_new += '/'
                                    }
                                    i++
                                }
                            }else{
                                approval_level_new = 'su';
                                isenable_approval_su = true
                                for(let k of approvers){
                                    specificUserID_app_level.push(parseInt(k.id))
                                }
                            }
                        }
                    }
                    console.log("approval_level_new===>", approval_level_new)
                    approval_level = approval_level_new
                }else if(module_config.approval_level.enable_enhanced_approval && module_config.approval_level.enable_approval_levels && module_config.approval_level.applies_to_all_gift_types === false){
                    let approval_giftTypes = module_config.approval_level.approval_giftTypes
                    let typeOfApproval = [];
                    for(let d of approval_giftTypes){
                        if(parseInt(d.giftTypeID) === parseInt(this.state.giftTypeid)){
                            typeOfApproval = d.elem;
                        }
                    }

                    let app_level = ''
                    for(let d of typeOfApproval){
                        if(this.state.giftStatus === 'given'){
                            if(d.status && parseInt(d.givenApproval.value) <= Math.round(parseFloat(minvaluecheck))){
                                app_level = d.level
                            }
                        }
                        if(this.state.giftStatus === 'received'){
                            if(d.status && parseInt(d.receivedApproval.value) <= Math.round(parseFloat(minvaluecheck))){
                                app_level = d.level
                            }
                        }
                    }
                    let approval_level_new = "";
                    for(let d of typeOfApproval){
                        if(this.state.giftStatus === 'given' && d.status && d.level === app_level){
                            let approvers = d.givenApproval.approvers;
                            let i = 0;
                            if(d.givenApproval.approver_type !== 'Specific User(s)'){
                                for(let k of approvers){
                                    approval_level_new += k.id;
                                    if(i < approvers.length - 1) {
                                        approval_level_new += '/'
                                    }
                                    i++
                                }
                            }else{
                                approval_level_new = 'su';
                                isenable_approval_su = true
                                for(let k of approvers){
                                    specificUserID_app_level.push(parseInt(k.id))
                                }
                            }
                        }
                        if(this.state.giftStatus === 'received' && d.status && d.level === app_level){
                            let approvers = d.receivedApproval.approvers;
                            let i = 0;
                            if(d.receivedApproval.approver_type !== 'Specific User(s)'){
                                for(let k of approvers){
                                    approval_level_new += k.id;
                                    if(i < approvers.length - 1) {
                                        approval_level_new += '/'
                                    }
                                    i++
                                }
                            }else{
                                approval_level_new = 'su';
                                isenable_approval_su = true
                                for(let k of approvers){
                                    specificUserID_app_level.push(parseInt(k.id))
                                }
                            }
                        }
                    }
                    console.log("approval_level_new===>", approval_level_new)
                    approval_level = approval_level_new === '' ? 'none' : approval_level_new
                }

                let repNameAll = this.state.recipientNameAll
                let contracts = Store.getStoreData('contacts');
                let reciname = this.state.recipientName;
                let check_recipient_id = 0
                if(this.state.recipientName !== 'Other'){
                    repNameAll = contracts[this.state.recipientName]
                    reciname = contracts[this.state.recipientName]
                    check_recipient_id = this.state.recipientName;
                }

                let specificUserID = this.state.specificUserID
                let isUserlevelApproval = false
                // Skip Two level approval start
                let pre_app_level = ""
                if(approval_level === 'ar-lm' || approval_level === 'lm-ar'){
                    if('user_level_approval' in module_config && check_recipient_id.toString() in module_config.user_level_approval){
                        let user_level_approval = module_config.user_level_approval[check_recipient_id.toString()]
                        pre_app_level = approval_level;
                        approval_level = user_level_approval.app_req_id;

                        if(user_level_approval.app_req_id === 'su'){
                            if('su_user' in user_level_approval && Object.keys(user_level_approval.su_user).length !== 0){
                                specificUserID = user_level_approval.su_user.value
                                isUserlevelApproval = true
                            }
                        }
                    }
                }
                // Skip Two level approval end
                // Skip One level approval start
                if(approval_level === 'ar' || approval_level === 'lm'){
                    if('approval_level' in module_config && module_config.approval_level.enable_enhanced_approval === true && module_config.approval_level.enable_approval_levels === false){
                        if('user_level_approval' in module_config && check_recipient_id.toString() in module_config.user_level_approval){
                            let user_level_approval = module_config.user_level_approval[check_recipient_id.toString()]
                            pre_app_level = approval_level;
                            approval_level = user_level_approval.app_req_id;

                            if(user_level_approval.app_req_id === 'su'){
                                if('su_user' in user_level_approval && Object.keys(user_level_approval.su_user).length !== 0){
                                    specificUserID = user_level_approval.su_user.value
                                    isUserlevelApproval = true
                                }
                            }
                        }
                    }
                }
                // Skip One level approval end

                let headerinfo = {};
                headerinfo.gift_status = this.state.giftStatus;
                // headerinfo.recipient_name = this.state.recipientNameAll;
                headerinfo.donor_name = this.state.donorName;
                headerinfo.gift_typeid = parseInt(this.state.giftTypeid);
                headerinfo.currency = this.state.currency;
                headerinfo.minvalue = this.state.minvalue;
                headerinfo.gift_description = this.state.giftDescription;
                // headerinfo.check_recipient_value = this.state.recipientName;
                headerinfo.recipient_name = repNameAll;
                headerinfo.check_recipient_value = reciname;
                headerinfo.check_recipient_id = check_recipient_id;
                headerinfo.giftedDate = this.state.giftedDate;
                headerinfo.additional_fields = this.state.assign_data.additional_fields;
                headerinfo.approval_level = approval_level;
                headerinfo.pre_app_level = pre_app_level;
                headerinfo.specificUserID = specificUserID;
                headerinfo.specificUserID_app_level = specificUserID_app_level;
                headerinfo.isenable_approval_su = isenable_approval_su;
                headerinfo.giftGivenTo = this.state.giftGivenTo;
                headerinfo.app_currency = this.state.app_currency;
                headerinfo.app_minvalue = this.state.app_minvalue;
                headerinfo.isShowAppValue = this.state.isShowAppValue;
                headerinfo.isUserlevelApproval = isUserlevelApproval;

                if (approval_level === "nr") {
                    submit_form = 2
                    headerinfo.approval_type = 'Not required (Auto-approve)';
                    headerinfo.approval_description = '';
                }

                let final_object_data = {};
                final_object_data.headerinfo = headerinfo;
                //final_object_data.ref_id = this.state.Ref_ID;

                let curmonth = parseInt(this.state.due_date.getMonth());
                let currmonth = curmonth + 1;
                var dueDateStr = currmonth >= 10 ? this.state.due_date.getFullYear()+'/'+currmonth+'/' : this.state.due_date.getFullYear()+'/0'+currmonth+'/';
                dueDateStr += this.state.due_date.getDate() < 10 ? '0' + this.state.due_date.getDate() : this.state.due_date.getDate();
                let bin_files = this.state.cur_files_json === null ? this.state.binFiles : [...this.state.binFiles, ...this.state.cur_files_json];
                console.log("bin_files==>",bin_files)

                //new email to su and lm
                let apov_lvl = approval_level;
                let aplv = apov_lvl.split("/")
                if(apov_lvl === 'lm-ar'){
                    apov_lvl = 'lm'
                }else if(aplv.length !== 0){
                    if(aplv.length === 1){
                        apov_lvl = aplv[0]
                    }else if(aplv.length === 3){
                        apov_lvl = 'amgmlm'
                    }else{
                        aplv.sort();
                        let cval = '';
                        for(let d of aplv){
                            cval += d.toString()
                        }
                        apov_lvl = cval
                    }
                    // for(let d of aplv){
                    //     if(d.toString() === 'lm'){
                    //         apov_lvl = 'lm'
                    //     }
                    //     if(d.toString() === 'su'){
                    //         apov_lvl = 'su'
                    //     }
                    // }
                }
                console.log("apov_lvl===>", apov_lvl)
                //new email to su and lm

                let task_json = {
                    "task_data": { "module_id": "gifts", "last_action": "COMP_GIFTS_REGISTER", "actiondate": Date.now(),
                    'system_id' : '1001', 'cur_lane' : 'COMP_GIFTS_REGISTER',
                    "cur_assigned_to" : 0, 'task_type' : 'COMP_GIFTS', 'parent_task_id' : 0,'due_date' : dueDateStr,
                    "group_company_id": company_id},
                    "object_data": final_object_data,
                    "action_data": { "action": "COMP_GIFTS_REGISTER", "actiondate": Date.now(), "assigned_to": 0, "cur_lane":"COMP_GIFTS_REGISTER"}
                };
                if(submit_form === 2) {
                    task_json = {
                        "task_data": { "module_id": "gifts", "last_action": "COMP_GIFTS_COMPLETE", "actiondate": Date.now(),
                        'group_company_id': company_id,
                        'system_id' : '1001', 'cur_lane' : 'COMP_GIFTS_COMPLETE',
                        "cur_assigned_to" : 0, 'task_type' : 'COMP_GIFTS', 'parent_task_id' : 0 , 'due_date' : dueDateStr},
                        "object_data": final_object_data,
                        "action_data": { "action": "COMP_GIFTS_COMPLETE", "actiondate": Date.now(), "assigned_to": 0, "cur_lane":"COMP_GIFTS_COMPLETE", "approval_type": 'Not required (Auto-approve)',
                        "approval_description": ''}
                    }
                }
                // console.log("task jason", JSON.stringify(task_json));
                let postData = {command: "update_gifts_task", task_json: task_json, bin_files: bin_files, submit: submit_form, taskid: this.state.taskid,
                                role: Store.getStoreData('role'), gc_id: company_id, apov_lvl: apov_lvl
                            };
                console.log("postData",postData)
                this.setState({submit_form,is_disabled: true, loading: true})
                let api = new APICall();
                api.command(postData, this.processUpdateGiftsData);
            }
        }else{
            if(this.state.cur_gc_id === 0){
                let alert_param = {title: 'Warning', message: 'Please select company.', ok_text: 'OK', confirm: false, alertHandler: this.samealertremoveHandler, stack: {id: 0}}
                this.setState({alert_param: alert_param})
                return
            }

            let minvaluecheck = this.state.minvalue;
            if(this.state.isShowAppValue){
                minvaluecheck = this.state.app_minvalue;
            }

            let approval_level = "none";
            let isenable_approval_su = false
            let specificUserID_app_level = [];
            if(module_config.approval_level.enable_enhanced_approval && module_config.approval_level.enable_approval_levels === false){
                let giftTypes = module_config.gift_types;
                for(let d of giftTypes){
                    if(d.isActive === 1 && parseInt(d.giftTypeID) === parseInt(this.state.giftTypeid)){
                        approval_level = 'approver_level' in d ? d.approver_level : "none";
                    }
                }
            }else if(module_config.approval_level.enable_enhanced_approval && module_config.approval_level.enable_approval_levels && module_config.approval_level.applies_to_all_gift_types){
                let approval = module_config.approval_level.approval[0].elem
                let app_level = ''
                for(let d of approval){
                    if(this.state.giftStatus === 'given'){
                        if(d.status && parseInt(d.givenApproval.value) <= Math.round(parseFloat(minvaluecheck))){
                            app_level = d.level
                        }
                    }
                    if(this.state.giftStatus === 'received'){
                        if(d.status && parseInt(d.receivedApproval.value) <= Math.round(parseFloat(minvaluecheck))){
                            app_level = d.level
                        }
                    }
                }
                let approval_level_new = "";
                for(let d of approval){
                    if(this.state.giftStatus === 'given' && d.status && d.level === app_level){
                        let approvers = d.givenApproval.approvers;
                        let i = 0;
                        if(d.givenApproval.approver_type !== 'Specific User(s)'){
                            for(let k of approvers){
                                approval_level_new += k.id;
                                if(i < approvers.length - 1) {
                                    approval_level_new += '/'
                                }
                                i++
                            }
                        }else{
                            approval_level_new = 'su';
                            isenable_approval_su = true
                            for(let k of approvers){
                                specificUserID_app_level.push(parseInt(k.id))
                            }
                        }
                    }
                    if(this.state.giftStatus === 'received' && d.status && d.level === app_level){
                        let approvers = d.receivedApproval.approvers;
                        let i = 0;
                        if(d.receivedApproval.approver_type !== 'Specific User(s)'){
                            for(let k of approvers){
                                approval_level_new += k.id;
                                if(i < approvers.length - 1) {
                                    approval_level_new += '/'
                                }
                                i++
                            }
                        }else{
                            approval_level_new = 'su';
                            isenable_approval_su = true
                            for(let k of approvers){
                                specificUserID_app_level.push(parseInt(k.id))
                            }
                        }
                    }
                }
                console.log("approval_level_new===>", approval_level_new)
                approval_level = approval_level_new
            }else if(module_config.approval_level.enable_enhanced_approval && module_config.approval_level.enable_approval_levels && module_config.approval_level.applies_to_all_gift_types === false){
                let approval_giftTypes = module_config.approval_level.approval_giftTypes
                let typeOfApproval = [];
                for(let d of approval_giftTypes){
                    if(parseInt(d.giftTypeID) === parseInt(this.state.giftTypeid)){
                        typeOfApproval = d.elem;
                    }
                }

                let app_level = ''
                for(let d of typeOfApproval){
                    if(this.state.giftStatus === 'given'){
                        if(d.status && parseInt(d.givenApproval.value) <= Math.round(parseFloat(minvaluecheck))){
                            app_level = d.level
                        }
                    }
                    if(this.state.giftStatus === 'received'){
                        if(d.status && parseInt(d.receivedApproval.value) <= Math.round(parseFloat(minvaluecheck))){
                            app_level = d.level
                        }
                    }
                }
                let approval_level_new = "";
                for(let d of typeOfApproval){
                    if(this.state.giftStatus === 'given' && d.status && d.level === app_level){
                        let approvers = d.givenApproval.approvers;
                        let i = 0;
                        if(d.receivedApproval.approver_type !== 'Specific User(s)'){
                            for(let k of approvers){
                                approval_level_new += k.id;
                                if(i < approvers.length - 1) {
                                    approval_level_new += '/'
                                }
                                i++
                            }
                        }else{
                            approval_level_new = 'su';
                            isenable_approval_su = true
                            for(let k of approvers){
                                specificUserID_app_level.push(parseInt(k.id))
                            }
                        }
                    }
                    if(this.state.giftStatus === 'received' && d.status && d.level === app_level){
                        let approvers = d.receivedApproval.approvers;
                        let i = 0;
                        if(d.receivedApproval.approver_type !== 'Specific User(s)'){
                            for(let k of approvers){
                                approval_level_new += k.id;
                                if(i < approvers.length - 1) {
                                    approval_level_new += '/'
                                }
                                i++
                            }
                        }else{
                            approval_level_new = 'su';
                            isenable_approval_su = true
                            for(let k of approvers){
                                specificUserID_app_level.push(parseInt(k.id))
                            }
                        }
                    }
                }
                console.log("approval_level_new===>", approval_level_new)
                approval_level = approval_level_new === '' ? 'none' : approval_level_new
            }

            let repNameAll = this.state.recipientNameAll
            let contracts = Store.getStoreData('contacts');
            let reciname = this.state.recipientName;
            let check_recipient_id = 0
            if(this.state.recipientName !== 'Other'){
                repNameAll = contracts[this.state.recipientName]
                reciname = contracts[this.state.recipientName]
                check_recipient_id = this.state.recipientName;
            }

            let headerinfo = {};
            headerinfo.gift_status = this.state.giftStatus;
            // headerinfo.recipient_name = this.state.recipientNameAll;
            headerinfo.donor_name = this.state.donorName;
            headerinfo.gift_typeid = parseInt(this.state.giftTypeid);
            headerinfo.currency = this.state.currency;
            headerinfo.minvalue = this.state.minvalue;
            headerinfo.gift_description = this.state.giftDescription;
            // headerinfo.check_recipient_value = this.state.recipientName;
            headerinfo.recipient_name = repNameAll;
            headerinfo.check_recipient_value = reciname;
            headerinfo.check_recipient_id = check_recipient_id;
            headerinfo.giftedDate = this.state.giftedDate;
            headerinfo.additional_fields = this.state.assign_data.additional_fields;
            headerinfo.approval_level = approval_level;
            headerinfo.specificUserID = this.state.specificUserID;
            headerinfo.specificUserID_app_level = specificUserID_app_level;
            headerinfo.isenable_approval_su = isenable_approval_su;
            headerinfo.giftGivenTo = this.state.giftGivenTo;
            headerinfo.app_currency = this.state.app_currency;
            headerinfo.app_minvalue = this.state.app_minvalue;
            headerinfo.isShowAppValue = this.state.isShowAppValue;

            let final_object_data = {};
            final_object_data.headerinfo = headerinfo;

            let bin_files = this.state.cur_files_json === null ? this.state.binFiles : [...this.state.binFiles, ...this.state.cur_files_json];
            console.log("bin_files==>",bin_files)

            let task_json = {};
            if(submit_form === 1) {
                task_json = {
                    "task_data": { "module_id": "gifts", "last_action": "COMP_GIFTS_REGISTER", "actiondate": Date.now(),
                    'system_id' : '1001', 'cur_lane' : 'COMP_GIFTS_REGISTER','company_id' : company_id, 'group_company_id': company_id,
                    "cur_assigned_to" : 0, 'task_type' : 'COMP_GIFTS', 'parent_task_id': 0 },
                    "object_data": final_object_data,
                    "action_data": { "action": "COMP_GIFTS_REGISTER", "actiondate": Date.now(), "assigned_to": 0, "cur_lane":"COMP_GIFTS_REGISTER"}
                };
            }else{
                task_json = {
                    "task_data": { "module_id": "gifts", "last_action": "COMP_GIFTS_TEAMSAVE", "actiondate": Date.now(),
                    'system_id' : '1001', 'cur_lane' : 'COMP_GIFTS_TEAMSAVE', 'company_id' : company_id, 'group_company_id': company_id,
                    'task_type' : 'COMP_GIFTS', 'parent_task_id' : 0 },
                    "object_data": final_object_data,
                    "action_data": { "action": "COMP_GIFTS_TEAMSAVE", "actiondate": Date.now(), "assigned_to": 0, "cur_lane":"COMP_GIFTS_TEAMSAVE"}
                };
            }
            // console.log("task jason", JSON.stringify(task_json));
            let postData = {command: "update_gifts_task", task_json: task_json,bin_files: bin_files, submit: submit_form,
            taskid: this.state.taskid, gc_company_id: company_id};
            console.log("postData",postData)
            this.setState({submit_form,is_disabled: true, loading: true})
            let api = new APICall();
            api.command(postData, this.processUpdateGiftsData);
        }
    }

    processConfirmationUpdate = (result, stack) => {
        if(!result) {
            this.setState({alert_param: null, giftStatus: "received",recipientName: "",recipientNameAll: "",donorName: "",giftTypeid: 0,giftDescription: ""})
            return
        }
        this.setState({alert_param: null})
        let company_id = this.state.companyID;
        const  module_config = Store.getStoreData('module_config');
        let submit_form = stack.submit_form

        let approval_level = "none";
        if('enable_auto_approval' in module_config.general && module_config.general.enable_auto_approval){
            approval_level = 'aa'
        }else if('enable_auto_approval' in module_config.general && module_config.general.enable_auto_approval === false){
            if(module_config.approval_level.enable_enhanced_approval && module_config.approval_level.enable_approval_levels === false){
                let giftTypes = module_config.gift_types;
                for(let d of giftTypes){
                    if(d.isActive === 1 && parseInt(d.giftTypeID) === parseInt(this.state.giftTypeid)){
                        approval_level = d.approver_level;
                    }
                }
            }
            // else if(module_config.approval_level.enable_enhanced_approval && module_config.approval_level.enable_approval_levels && module_config.approval_level.applies_to_all_gift_types){
            //     if(module_config.approval_level.approval[0].elem.length !== 0){
            //         approval_level = 'nr'
            //     }
            // }else if(module_config.approval_level.enable_enhanced_approval && module_config.approval_level.enable_approval_levels && module_config.approval_level.applies_to_all_gift_types === false){
            //     for(let d of module_config.approval_level.approval_giftTypes){
            //         if(parseInt(d.giftTypeID) === parseInt(this.state.giftTypeid)){
            //             approval_level = 'nr'
            //         }
            //     }
            // }
        }

        let repNameAll = this.state.recipientNameAll
        let contracts = Store.getStoreData('contacts');
        let reciname = this.state.recipientName;
        let check_recipient_id = 0
        if(this.state.recipientName !== 'Other'){
            repNameAll = contracts[this.state.recipientName]
            reciname = contracts[this.state.recipientName]
            check_recipient_id = this.state.recipientName;
        }

        let headerinfo = {};
        headerinfo.gift_status = this.state.giftStatus;
        // headerinfo.recipient_name = this.state.recipientNameAll;
        headerinfo.donor_name = this.state.donorName;
        headerinfo.gift_typeid = parseInt(this.state.giftTypeid);
        headerinfo.currency = this.state.currency;
        headerinfo.minvalue = this.state.minvalue;
        headerinfo.gift_description = this.state.giftDescription;
        // headerinfo.check_recipient_value = this.state.recipientName;
        headerinfo.recipient_name = repNameAll;
        headerinfo.check_recipient_value = reciname;
        headerinfo.check_recipient_id = check_recipient_id;
        headerinfo.giftedDate = this.state.giftedDate;
        headerinfo.additional_fields = this.state.assign_data.additional_fields;
        headerinfo.approval_level = approval_level;
        headerinfo.specificUserID = this.state.specificUserID;
        headerinfo.specificUserID_app_level = [];
        headerinfo.isenable_approval_su = false;
        headerinfo.giftGivenTo = this.state.giftGivenTo;
        headerinfo.app_currency = this.state.app_currency;
        headerinfo.app_minvalue = this.state.app_minvalue;
        headerinfo.isShowAppValue = this.state.isShowAppValue;

        if (approval_level === "nr") {
            submit_form = 2
            headerinfo.approval_type = 'Not required (Auto-approve)';
            headerinfo.approval_description = '';
        }
        if (approval_level === "aa") {
            submit_form = 2
            headerinfo.approval_type = 'Auto approved';
            headerinfo.approval_description = '';
        }

        let final_object_data = {};
        final_object_data.headerinfo = headerinfo;
        //final_object_data.ref_id = this.state.Ref_ID;

        let curmonth = parseInt(this.state.due_date.getMonth());
        let currmonth = curmonth + 1;
        var dueDateStr = currmonth >= 10 ? this.state.due_date.getFullYear()+'/'+currmonth+'/' : this.state.due_date.getFullYear()+'/0'+currmonth+'/';
        dueDateStr += this.state.due_date.getDate() < 10 ? '0' + this.state.due_date.getDate() : this.state.due_date.getDate();
        let bin_files = this.state.cur_files_json === null ? this.state.binFiles : [...this.state.binFiles, ...this.state.cur_files_json];
        console.log("bin_files==>",bin_files)

        let task_json = {
            "task_data": { "module_id": "gifts", "last_action": "COMP_GIFTS_REGISTER", "actiondate": Date.now(),
            'system_id' : '1001', 'cur_lane' : 'COMP_GIFTS_REGISTER',
            "cur_assigned_to" : 0, 'task_type' : 'COMP_GIFTS', 'parent_task_id' : 0,'due_date' : dueDateStr,
            "group_company_id": company_id},
            "object_data": final_object_data,
            "action_data": { "action": "COMP_GIFTS_REGISTER", "actiondate": Date.now(), "assigned_to": 0, "cur_lane":"COMP_GIFTS_REGISTER"}
        };
        if(submit_form === 2) {
            task_json = {
                "task_data": { "module_id": "gifts", "last_action": "COMP_GIFTS_COMPLETE", "actiondate": Date.now(),
                'group_company_id': company_id,
                'system_id' : '1001', 'cur_lane' : 'COMP_GIFTS_COMPLETE',
                "cur_assigned_to" : 0, 'task_type' : 'COMP_GIFTS', 'parent_task_id' : 0 , 'due_date' : dueDateStr},
                "object_data": final_object_data,
                "action_data": { "action": "COMP_GIFTS_COMPLETE", "actiondate": Date.now(), "assigned_to": 0, "cur_lane":"COMP_GIFTS_COMPLETE", "approval_type": 'Not required (Auto-approve)',
                "approval_description": ''}
            }
        }
        // console.log("task jason", JSON.stringify(task_json));
        let postData = {command: "update_gifts_task", task_json: task_json, bin_files: bin_files, submit: submit_form, taskid: this.state.taskid,role: Store.getStoreData('role'), gc_id: company_id };
        console.log("postData",postData)
        this.setState({submit_form,is_disabled: true, loading: true})
        let api = new APICall();
        api.command(postData, this.processUpdateGiftsData);
    }

    processUpdateGiftsData = (result) => {
        console.log("result", result)
        let msg = '';
        let title = 'Alert';
        this.setState({loading: false})
        if(this.state.submit_form === 1){
            msg = 'Gift registered successfully'
            // alert('Gift registered successfully');
            let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.alertremoveHandler, stack: {id: 0}}
            this.setState({alert_param: alert_param, loading: false})
        }else if(this.state.submit_form === 2){
            let alert_param = {title: title, message: 'Gift added successfully', ok_text: 'OK', confirm: false, alertHandler: this.alertremoveHandler, stack: {id: 0}}
            this.setState({alert_param: alert_param, loading: false})
        }else {
            msg = 'Gift updated successfully'
            // alert('Gift updated successfully');
            let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.alertremoveHandler, stack: {id: 0}}
            this.setState({alert_param: alert_param, loading: false})
        }
        // window.location.reload();
    }

    handleChange = (evt) => {
        this.setState({[evt.target.name]: evt.target.value});
    }

    handleChangeValue = (evt) => {
        let isShowAppValue = false
        let currencyValue = "";
        if('general' in Store.getStoreData('module_config') && 'companyCurrencyDetails' in Store.getStoreData('module_config').general){
            currencyValue = Store.getStoreData('module_config').general.companyCurrencyDetails.currency;
        }
        if(currencyValue !== evt.target.value){
            isShowAppValue = true
        }
        this.setState({[evt.target.name]: evt.target.value, isShowAppValue});
    }

    handleChangeGiftType = (evt) => {
        const  module_config = Store.getStoreData('module_config');
        let giftTypes = module_config.gift_types;
        let isShowSpecificUsers = false
        if('enable_enhanced_approval' in module_config.approval_level && module_config.approval_level.enable_enhanced_approval && module_config.approval_level.enable_approval_levels === false){
            for(let d of giftTypes){
                if(d.isActive === 1 && parseInt(d.giftTypeID) === parseInt(evt.target.value) && 'approver_level' in d && d.approver_level === 'su'){
                    isShowSpecificUsers = true
                }
            }
        }
        console.log("isShowSpecificUsers===>", isShowSpecificUsers)
        let minvalue = this.state.fixedMinvalue;
        if('approval_level' in module_config && module_config.approval_level.enable_enhanced_approval && module_config.approval_level.enable_approval_levels && module_config.approval_level.applies_to_all_gift_types === false){
            for(let d of module_config.approval_level.approval_giftTypes){
                if(parseInt(d.giftTypeID) === parseInt(evt.target.value)){
                    minvalue = this.state.giftStatus === 'given' ? d.elem[0].given.value : d.elem[0].received.value
                }
            }
            this.setState({finalMinValueCheck: minvalue})
        }
        this.setState({[evt.target.name]: evt.target.value, isShowSpecificUsers});
    }

    handleChangeReceiveGiven = (evt) => {
        //custom field start
        let module_config = Store.getStoreData('module_config');
        let additional_fields = [];
        if(Object.keys(module_config).length > 0) {
            if (module_config.custom_fields.additional_fields.length !== 0 && module_config.custom_fields.enabled) {
                let arrayAdditionalField = Object.values(module_config.custom_fields.additional_fields);
                arrayAdditionalField.forEach((item) => {
                    if(item.isActive === 1){
                        let copy_item = JSON.parse(JSON.stringify(item));
                        copy_item.value = item.type === "Calendar" ? new Date() : "";
                        copy_item.value = item.type === "Dropdown" ? "" : copy_item.value;
                        additional_fields.push(copy_item);
                    }
                })
            }
        }
        //console.log("additional_fields===>", additional_fields)
        //custom field end
        let assign_data = {};
        assign_data.additional_fields = additional_fields;

        let minvalue = this.state.fixedMinvalue;
        if('approval_level' in module_config && module_config.approval_level.enable_enhanced_approval && module_config.approval_level.enable_approval_levels && module_config.approval_level.applies_to_all_gift_types === false){
            for(let d of module_config.approval_level.approval_giftTypes){
                if(parseInt(d.giftTypeID) === parseInt(this.state.giftTypeid)){
                    minvalue = evt.target.value === 'given' ? d.elem[0].given.value : d.elem[0].received.value
                }
            }
            this.setState({finalMinValueCheck: minvalue})
        }else if('approval_level' in module_config && module_config.approval_level.enable_enhanced_approval && module_config.approval_level.enable_approval_levels && module_config.approval_level.applies_to_all_gift_types){
            if(module_config.approval_level.approval[0].elem.length === 0){
                minvalue = module_config.approval_level.min_val_approval
            }else{
                minvalue = evt.target.value === 'given' ? module_config.approval_level.approval[0].elem[0].given.value : module_config.approval_level.approval[0].elem[0].received.value
            }
            this.setState({finalMinValueCheck: minvalue})
        }
        this.setState({[evt.target.name]: evt.target.value, assign_data});
    }

    onChangeGiftedDate = (date) => {
        this.setState({giftedDate: date});
    }

    handleChangeDTTP = (evt) => {
        const {disclosure_to_third_party} = this.state;
        disclosure_to_third_party.description = evt.target.value;
        this.setState({ disclosure_to_third_party })
    }
    handleChangeAROD = (evt) => {
        const {additional_records_of_disclosure} = this.state;
        additional_records_of_disclosure.description = evt.target.value;
        this.setState({ additional_records_of_disclosure })
    }

    filesLoaded = (files) => {
        let contact_id = Store.getStoreData('contact_id');
        for(let i=0; i < files.length; i++) {
            files[i].name = files[i].name.replace('&', '');
            //console.log('File_name', files[i].name);
            if(!('user_id' in files[i])) {
                files[i].user_id = contact_id;
                files[i].upload_date = Date.now();
            }

        }
        //console.log('files in Modal', files);
        this.setState({binFiles: files});
    }

    generateField = (data, data_container) => {
    	return (
    		<div key={data.name}>
    			<MRModalLabel>
    			{
    				(() => {
    					if (data.required) {
    						return (<div>{data.nickname}<span style={{color: "red", paddingLeft: "5px", fontSize: "8px"}}><FaStarOfLife  title={data.nickname+" required"}/></span></div>);
    					} else {
    						return data.nickname;
    					}
    				})()
    			}
    			</MRModalLabel>
    			{
    				(() => {
    					let fieldhtml = <div></div>;
    					switch(data.type) {
    						case "Alpha":
    							fieldhtml = this.createAlphaField(data, data_container);
    							break;
    						case "Numeric":
    							fieldhtml = this.createNumericField(data, data_container);
    							break;
                            case "Dropdown":
                                fieldhtml = this.createDropdownField(data, data_container);
                                break;
                            case "Date":
                                fieldhtml = this.createDateField(data, data_container);
                                break;
    						default:
    							fieldhtml = fieldhtml;
    					}
                        if (data_container === "additional_fields")
    					return fieldhtml;
    				})()
    			}
    		</div>
    	);
    }

    onChangeCustomDate = (name) => (date) => {
        //event.preventDefault();
        let assign_data = JSON.parse(JSON.stringify(this.state.assign_data));
        console.log("assign_data===>", assign_data)
        console.log("name===>", name)
        assign_data.additional_fields.forEach((item) => {
            if (item.name === name) {
                item.value = date;
            }
        })
        console.log("ModifyAssigndata+++==>", assign_data)
        this.setState({assign_data: assign_data});
    }

    createDateField = (data, data_container) => {
        console.log("Data==>", data)
        let curValue = data.value !== '' ? new Date(data.value) : '';
		return (<CSLDateCover id={"date-"+data.name} style={{display: "inline-block"}}>
                    <DatePicker
                            onChange={this.onChangeCustomDate(data.name)}
                            clearIcon={null}
                            locale={"en-GB"}
                            calendarIcon={<FaCalendarAlt style={{color: '#243E57'}} />}
                            value={curValue}
                    />
                </CSLDateCover>);
    }

    createAlphaField = (data, data_container) => {
		return (<CSLTextInput id={"text-"+data.name} handleTextChange={this.handleTextChange} name={data.name} value={data.value} entity="additional_fields" field="value" />);
    }

    handleTextChange = (obj) => {
		console.log("obj:", obj);
		let assign_data = JSON.parse(JSON.stringify(this.state.assign_data));
		if (obj.entity === null) {
			assign_data[obj.name] = obj.value;
		} else {
			assign_data[obj.entity].forEach(item => {
				if (item.name === obj.name) item[obj.field] = obj.value;
			})
		}
        this.setState({assign_data: assign_data});
	}

    createNumericField = (data, data_container) => {
    	return (<MRModalInput id={"number-"+data.name} type="number" min={0} name={data.name} value={data.value} onKeyDown={blockInvalidChar} onChange={this.handleAddlField(data.type, data_container)} />);
    }

    handleAddlField = (field_type, data_container) => (event) => {
        event.preventDefault();
        let assign_data = JSON.parse(JSON.stringify(this.state.assign_data));
        console.log("event.target.name===>", event.target.name)
        console.log("assign_data===>", assign_data)
        console.log("data_container===>", data_container)
        if (event.target.name === "smcr_role") {
            assign_data[event.target.name] = event.target.value;
            assign_data[data_container].forEach((item) => {
                if (item.name === event.target.name) {
                    item.value = event.target.value;
                }
            })
        } else {
            assign_data[data_container].forEach((item) => {
                if (item.name === event.target.name) {
                    item.value = event.target.value;
                }
            })
        }
        console.log("ModifyAssigndata+++==>", assign_data)
        this.setState({assign_data: assign_data});
    }

    createDropdownField = (data, data_container) => {
        console.log("data.value==>", data.value)
    	return (
            <div>
        		<MRModalSelect id={"select-"+data.name} onChange={this.handleAddlField(data.type, data_container)} name={data.name} value={data.value}>
                    <option key="sel" value="">Select</option>
        		    {
        		        data.dropdown_options.map((opt, index) => {
        		            return(
    	    		            <option key={index} value={opt}>{opt}</option>
        		            );
        		        })
        		    }
                    {
                        (() => {
                            if (data_container === "additional_fields") {
                                if ("other" in data) {
                                    if (data.other === true) {
                                        return (<option value="other">Other</option>);
                                    }
                                }
                            }
                        })()
                    }
        		</MRModalSelect>
                {
                    (() => {
                        if (data_container === "additional_fields") {
                            if ("other" in data) {
                                if (data.other === true && data.value === "other") {
                                    return (
                                        <div style={{marginTop: "15px"}}>
                                            <div style={{float: "left", width: "20%", boxSizing: "border-box", paddingTop: "12px"}}>
                                                <MRModalLabel style={{margin: "0px"}}>Please give details</MRModalLabel>
                                            </div>
                                            <div style={{float: "left", width: "80%", boxSizing: "border-box"}}>
                                                <CSLTextInput id={"text-"+data.name} entity="additional_fields" name={data.name} value={data.other_value} handleTextChange={this.handleTextChange} field="other_value"/>
                                            </div>
                                            <div style={{clear: "both"}}></div>
                                        </div>
                                    );
                                }
                            }
                        }
                    })()
                }
            </div>
    	);
    }

    render()
    {
        if(!this.state.ready) {
            return(<div>Loading...</div>);
        }
        let module_config = Store.getStoreData('module_config');
        return (
            <div>
                {
                    (() => {
                        if(this.state.loading){
                            return <div><CSLLoader style={{position: 'absolute'}}/></div>
                        }
                    })()
                }
                <MRModalContainer>
                    <MRModalHeader>
                        <MRModalHeaderText>Add Gifts & Hospitality Entry</MRModalHeaderText>
                        <MRModalHeaderCloseBtn onClick={this.closeView}><FaTimes /></MRModalHeaderCloseBtn>
                        <div style={{clear: "both"}}></div>
                        <MRModalSubHeaderText>Fill out the below fields to fully submit your Gifts & Hospitality disclosure.</MRModalSubHeaderText>
                        <div style={{clear: "both"}}></div>
                    </MRModalHeader>
                    <ScoreBodyContainer>
                    <MRModalBody>
                        <div style={{clear: "both"}}></div>
                        <div style={{position: "relative",padding: "35px 35px"}}>
                        {/* <div style={{paddingTop: '20px',marginBottom: "10px"}}>
                            <ModalNameLabelDivReference>GIFTS ID</ModalNameLabelDivReference>
                            <ModalNameLabelDivReferenceID>{this.state.Ref_ID}</ModalNameLabelDivReferenceID>
                        </div> */}
                        {
                            (() => {
                                if(Store.getStoreData('q_index') >= 0) {
                                    return(
                                        <div>
                                            <ModalNameLabelDivReference>Company - </ModalNameLabelDivReference>
                                            <MRModalLabel style={{display: "inline-block",marginLeft: "5px"}}>{this.state.currentcompanyName}</MRModalLabel>
                                        </div>
                                    )
                                }else{
                                    if(Store.getStoreData('gc_companies').length === 0){
                                        return(
                                            <div>
                                                <ModalNameLabelDivReference>Company - </ModalNameLabelDivReference>
                                                {
                                                    (() => {
                                                        if(this.state.gc_company.length !== 0){
                                                            return(<MRModalLabel style={{display: "inline-block",marginLeft: "5px"}}>{this.state.gc_company[0].company_name}</MRModalLabel>)
                                                        }
                                                    })()
                                                }
                                            </div>
                                        )
                                    }else{
                                        return(
                                            <div>
                                                <MRModalLabel>Select Company </MRModalLabel>
                                                <MRModalSelect id="select_company" name="cur_gc_id" value={this.state.cur_gc_id} onChange={this.handleChangeCurGc}>
                                                <option key={0} value={0}>Select</option>
                                                    {
                                                            this.state.gc_company.map((gc) => {
                                                            return(
                                                            <option key={gc.id} value={gc.id}>{gc.company_name}</option>
                                                            );
                                                        })
                                                    }
                                                </MRModalSelect>
                                            </div>
                                        )
                                    }
                                }
                            })()
                        }
                            <SpacerS/>
                            <MRModalLabel>Type</MRModalLabel>
                            <MRModalSelect id='type' name="giftTypeid" onChange={this.handleChangeGiftType} value={this.state.giftTypeid}>
                                <option value={0}>Select</option>
                                {
                                    this.state.giftTypeList.map((gf,index) => {
                                        return(
                                            <option key={index} value={gf.giftTypeID}>{gf.giftTypeName}</option>
                                        );
                                    })
                                }
                            </MRModalSelect>
                            {
                                (() => {
                                    if(this.state.isShowSpecificUsers){
                                        return(<div>
                                            <SpacerS/>
                                            <MRModalLabel>Select a specific user who will be sent a notification to approve this record</MRModalLabel>
                                            <MRModalSelect id='specificUserID' name="specificUserID" onChange={this.handleChange} value={this.state.specificUserID} >
                                                <option value="">Select</option>
                                                {
                                                    this.state.specificUsersList.map((rc,index) => {
                                                        return(
                                                        <option key={index} value={rc.id}>{rc.name}</option>
                                                        );
                                                    })
                                                }
                                            </MRModalSelect>
                                        </div>)
                                    }
                                })()
                            }
                            <SpacerS/>
                            <MRModalLabel>Was the gift received or given?</MRModalLabel>
                            <MRModalSelect id='received_given' name="giftStatus" onChange={this.handleChangeReceiveGiven} value={this.state.giftStatus} >
                                <option value="received">Received</option>
                                <option value="given">Given</option>
                            </MRModalSelect>
                            <SpacerS/>
                            <MRModalLabel>Gifted Date</MRModalLabel>
                            <CSLDateCover id="gifted_date" style={{display: "inline-block"}}>
                                <DatePicker
                                        onChange={this.onChangeGiftedDate}
                                        clearIcon={null}
                                        calendarIcon={<FaCalendarAlt style={{color: '#243E57'}} />}
                                        locale={"en-GB"}
                                        value={this.state.giftedDate}
                                />
                            </CSLDateCover>
                            <SpacerS/>
                            {
                                (() => {
                                    // console.log("this.state.recipientName===>", this.state.recipientName)
                                    if(this.state.giftStatus === "received"){
                                        return(<MRModalLabel>Recipient</MRModalLabel>)
                                    }else{
                                        return(<MRModalLabel>Given By</MRModalLabel>)
                                    }
                                })()
                            }
                            <MRModalSelect id='recipient' name="recipientName" onChange={this.handleChangeRecipient} value={this.state.recipientName}>
                                <option value="">Select</option>
                                {
                                    this.state.recipientlist.map((rc,index) => {
                                        return(
                                        <option key={index} value={rc.id}>{rc.name}</option>
                                        );
                                    })
                                }
                            </MRModalSelect>
                            {
                                (() => {
                                    if(this.state.isOtherRecipientName){
                                        return(<div>
                                            <SpacerS/>
                                            <MRModalLabel>Please provide details</MRModalLabel>
                                            <MRModalInput id='recipient_name' type="text" name="recipientNameAll" onChange={this.handleChange} value={this.state.recipientNameAll} />
                                        </div>)
                                    }
                                })()
                            }
                            {
                                (() => {
                                    if(this.state.giftStatus === "given"){
                                        return(<div>
                                            <MRModalLabel style={{marginTop: "30px"}}>Given To</MRModalLabel>
                                            <MRModalTextarea id='giftGivenTo' rows="6" name="giftGivenTo" value={this.state.giftGivenTo} onChange={this.handleChange} readOnly={this.state.isdesable}/>
                                        </div>)
                                    }
                                })()
                            }
                            {
                                (() => {
                                    if(this.state.giftStatus === "received"){
                                        return(<div>
                                            <SpacerS/>
                                            <MRModalLabel>Donor</MRModalLabel>
                                            <MRModalInput id='donor' type="text" placeholder="Enter donor name..." name="donorName" onChange={this.handleChange} value={this.state.donorName} />
                                        </div>)
                                    }
                                })()
                            }
                            <SpacerS/>
                            <div style={{display: "inline-block", width: "50%"}}>
                                <MRModalLabel>Value</MRModalLabel>
                                {/* <MRModalInput style={{display: "inline-block",width: "10%"}} value={this.state.currency} readOnly/> */}
                                <MRModalSelect style={{display: "inline-block",width: "30%"}} name="currency" onChange={this.handleChangeValue} value={this.state.currency}>
                                    {currency_codes.map((cc) => (
                                        <option key={cc.code} value={cc.code}>
                                        {cc.code}
                                        </option>
                                    ))}
                                </MRModalSelect>
                                <MRModalInput style={{width: "25%",marginLeft: "30px"}} id='value' type="number" step="any" name="minvalue" min="0" onKeyDown={blockInvalidChar} onChange={this.handleChange} value={this.state.minvalue} />
                            </div>
                            {/* Appropixmat value */}
                            {
                                (() => {
                                    if(this.state.isShowAppValue){
                                        return(
                                        <div style={{display: "inline-block", width: "50%"}}>
                                            <MRModalLabel>Approximate {this.state.app_currency} value</MRModalLabel>
                                            <MRModalInput style={{display: "inline-block",width: "25%"}} value={this.state.app_currency} readOnly/>
                                            {/* <MRModalSelect style={{display: "inline-block",width: "30%"}} name="app_currency" onChange={this.handleChange} value={this.state.app_currency}>
                                                {currency_codes.map((cc) => (
                                                    <option key={cc.code} value={cc.code}>
                                                    {cc.code}
                                                    </option>
                                                ))}
                                            </MRModalSelect> */}
                                            <MRModalInput style={{width: "25%",marginLeft: "30px"}} id='app_value' type="number" step="any" name="app_minvalue" min="0" onKeyDown={blockInvalidChar} onChange={this.handleChange} value={this.state.app_minvalue} />
                                        </div>
                                        )
                                    }
                                })()
                            }

                            <SpacerS/>
                            {/* custom field start */}
                            {
                                (() => {
                                    if('custom_fields' in module_config && module_config.custom_fields.enabled){
                                        if (this.state.assign_data.additional_fields.length !== 0) {
                                            let custom_html = [];
                                            this.state.assign_data.additional_fields.forEach((item) => {
                                                if(item.displayFor === this.state.giftStatus){
                                                    const field = this.generateField(item, "additional_fields");
                                                    custom_html.push(field);
                                                }
                                                if(item.displayFor === 'both'){
                                                    const field = this.generateField(item, "additional_fields");
                                                    custom_html.push(field);
                                                }
                                            })
                                            return custom_html;
                                        }
                                    }
                                })()
                            }
                            {/* custom field end */}

                            <SpacerS/>
                            <MRModalLabel style={{marginTop: "30px"}}>Description</MRModalLabel>
                            <MRModalLabel style={{fontWeight: "100",fontSize: "13px",color: "#919292"}}>Please provide a description of the gift/hospitality</MRModalLabel>
                            <MRModalTextarea id='description' rows="6" name="giftDescription" value={this.state.giftDescription} onChange={this.handleChange} />
                            <SpacerS/>
                            <MRModalLabel>Attachments</MRModalLabel>
                            <ModalNameInputDiv>
                                <Dropzone onFilesAdded={this.filesLoaded} initFiles={this.state.cur_files_json} />
                            </ModalNameInputDiv>
                        </div>
                    </MRModalBody>
                    </ScoreBodyContainer>
                    <MRModalFooter>
                    {
                        (() => {
                            if(Store.getStoreData('q_index') >= 0) {
                                if(this.state.curlane === "COMP_GIFTS_TEAMSAVE") {
                                    return(<div style={{position: "relative",padding: "17px"}}>
                                    <MRModalNextBtn id='submit' onClick={this.updateRegisterGift(1)} disabled={this.state.is_disabled}>Submit</MRModalNextBtn>
                                    <MRModalSaveCloseBtn id='save_and_close' onClick={this.updateRegisterGift(0)} disabled={this.state.is_disabled}>Save and Close</MRModalSaveCloseBtn>
                                    <MRModalCancelBtn id="cancel" onClick={this.closeView}>Cancel</MRModalCancelBtn>
                                </div>);
                                }
                                return(
                                    <div style={{position: "relative",padding: "17px"}}>
                                        <MRModalCancelBtn onClick={this.closeView}>Cancel</MRModalCancelBtn>
                                    </div>
                                )
                            }else{
                                return(
                                <div style={{position: "relative",padding: "17px"}}>
                                    <MRModalNextBtn id='submit' onClick={this.submitRegisterGift(1)} disabled={this.state.is_disabled}>Submit</MRModalNextBtn>
                                    <MRModalSaveCloseBtn id='save_close' onClick={this.submitRegisterGift(0)} disabled={this.state.is_disabled}>Save and Close</MRModalSaveCloseBtn>
                                    <MRModalCancelBtn id="cancel" onClick={this.closeView}>Cancel</MRModalCancelBtn>
                                </div>
                                )
                            }
                        })()
                    }
                    </MRModalFooter>
                </MRModalContainer>
                <AlertBox alertParam = {this.state.alert_param}/>
            </div>);
    }
}

export default GMAskGiftModal;
